import { Close } from "@mui/icons-material";
import { Autocomplete, capitalize, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Tooltip } from "@mui/material";
import { consentProvisionTypes, ConsentWrapper, WrappedConsent } from "src/@nicheaim/fhir-base/wrappers/Consent";
import { OrganizationWrapper, WrappedOrganization } from "src/@nicheaim/fhir-base/wrappers/Organization";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { GridItem, GridSection } from "src/components/CustomModal";
import DatePickerMoment from "src/components/DatePickerMoment";
import { Consent, Organization, Reference, ValueSetComposeIncludeConcept } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import SearchMember, { MemberResourceTypes } from "src/sections/crs/case/components/SearchMember/SearchMember";
import CancelButton from "src/sections/crs/common/CancelButton";
import { CustomLoadingButton } from "src/sections/crs/common/CustomButton";
import SelectAttachment from "src/sections/crs/common/SelectAttachment";
import { ReferenceResources, RequesterResourceTypes, ResourceWithIncludedResources } from "src/sections/crs/types";
import { getResourceUniqueIdentifierPayload } from "src/services/api/fhirUtil";
import { getReferenceDisplayByResource, getReferenceId } from "src/utils/fhir";
import { useCallback, useEffect, useMemo } from "react";
import moment from "moment";
import { useConsents, useOrganizations, useValueSets } from "src/@nicheaim/fhir-react";
import { cleanSearchInput } from "src/utils/string";
import { debounce } from "src/utils/timers";
import useNotiMessage from "src/hooks/useNotiMessage";
import useLocales from "src/hooks/useLocales";
import useObjectState from 'src/hooks/useObjectState';
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { VERBAL_CONSENT } from "src/config";
import { SetDataExternal } from "src/sections/engagement/intake/components/workflow-step/ConsentInformation";

export type onSelectProperties = (
  isDialogsOpen: boolean,
  consentStatus?: string,
  provisionPeriodStart?: string,
  provisionPeriodEnd?: string
) => void;

export interface ConsentModalProps {
  open: boolean;
  patient: WrappedPatient | null;
  consentData: ResourceWithIncludedResources<WrappedConsent> | null;
  onSaveChanges: VoidFunction;
  onSelectProperties?: onSelectProperties;
  onClose: Function;
  dontShow?: boolean;
  setDataExternal?: SetDataExternal | null;
}

const requesterTypes = Object.entries(RequesterResourceTypes).map(([_, value]) => value);

export interface ConsentFormState {
  grantee: RequesterResourceTypes | ReferenceResources | null;
  isAttachmentsDialogOpen: boolean;
  isSearchMemberDialogOpen: boolean;
  selectedOrganization: WrappedOrganization | null;
  organizationInput: string;
  organizationFilter: string;
  createdOn: moment.Moment | null;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  status: ValueSetComposeIncludeConcept | null;
  scope: ValueSetComposeIncludeConcept | null;
  category: ValueSetComposeIncludeConcept | null;
  sourceReference: Reference | null;
  isVerbalConsent: boolean;
  provisionType: string | null;
  errors: ConsentFormErrorState | null;
  isLoading: boolean;
}

export interface ConsentFormErrorState {
  startDate?: string | null;
  scope?: string | null;
  status?: string | null;
  category?: string | null;
  grantee?: string | null;
}

const consentFormInitialState: ConsentFormState = {
  grantee: null,
  isAttachmentsDialogOpen: false,
  isSearchMemberDialogOpen: false,
  selectedOrganization: null,
  organizationInput: '',
  organizationFilter: '',
  createdOn: moment(),
  startDate: moment(),
  endDate: null,
  status: null,
  scope: null,
  category: null,
  errors: null,
  provisionType: 'permit',
  sourceReference: null,
  isVerbalConsent: false,
  isLoading: false,
};
const valueSetsIds = ['consent-state-codes', 'consent-category', 'consent-scope'];

const ConsentForm = ({
  open,
  onClose,
  patient,
  consentData,
  onSaveChanges,
  onSelectProperties,
  setDataExternal,
  dontShow
}: ConsentModalProps) => {

  const { i18n } = useLocales();
  const [
    {
      grantee,
      isAttachmentsDialogOpen,
      isSearchMemberDialogOpen,
      selectedOrganization,
      organizationInput,
      organizationFilter,
      createdOn,
      startDate,
      endDate,
      status,
      scope,
      category,
      errors,
      provisionType,
      sourceReference,
      isVerbalConsent,
      isLoading,
    },
    updateState,
  ] = useObjectState<ConsentFormState>(consentFormInitialState);

  const { showErrorMessage, showSuccessMessage } = useNotiMessage();

  const [, { create: createConsent, update: updateConsent }] = useConsents({
    autofetch: false,
    map: ConsentWrapper,
  });

  const [organizations, { isFetching: isOrganizationsLoading }] = useOrganizations({
    filter: {
      ...(organizationFilter ? { name: organizationFilter } : {}),
    },
    map: OrganizationWrapper,
    pagination: {
      pageSize: 50,
    },
  });

  const isDialogsOpen = isAttachmentsDialogOpen || isSearchMemberDialogOpen;

  const getOrganizations = useCallback(
    debounce((searchInput: string) => {
      updateState({ organizationFilter: cleanSearchInput(searchInput ?? '') });
    }, 600),
    []
  );
  const [valueSets] = useValueSets({
    filter: {
      _id: valueSetsIds.join(),
    },
    map: ValueSetWrapper,
  });

  const [consentStatusVS, consentCategoryVS, consentScopeVS] = useMemo(() => {
    if (!valueSets?.length) return [];

    return valueSetsIds.map((valueSetId) => valueSets.find(({ id }) => id === valueSetId) ?? null);
  }, [valueSets]);

  const getExternalProperties = useMemo(() => {
    
    const statusExternal = (consentStatusVS?.asListAll?.() ?? []).find(
      ({ code }) => code === setDataExternal?.status
    );
    const categoryExternal = (consentCategoryVS?.asListAll?.() ?? []).find(
      ({ code }) => code === setDataExternal?.consentType?.code);
    
    const scopeExternal = (consentScopeVS?.asListAll?.() ?? []).find(
      ({ code }) => code === setDataExternal?.scope);
    
    const granteeExternal: ReferenceResources = {
      reference: setDataExternal?.patientReference,
      display: setDataExternal?.patientName,
    };

    return {
      status: statusExternal,
      category: categoryExternal,
      scope: scopeExternal,
      grantee: granteeExternal
    }
  }, [consentCategoryVS, consentScopeVS, setDataExternal?.consentType]);

  const getStateFromConsentData = useCallback(() => {
    if (!open) return;
    if (!consentData) {

      const consentFormInitial = {
        ...consentFormInitialState,
        ...((setDataExternal?.consentType) && { ...getExternalProperties })
      }

      return updateState(consentFormInitial);
    }

    const { resource: consent, includedResources } = consentData;

    const grantee: ReferenceResources = {
      reference: consent?.performer?.[0]?.reference,
      display: consent.getFirstPerformerName(includedResources),
    };

    const organization = includedResources.find(
      ({ id }) => id === getReferenceId(consent?.organization?.[0]?.reference)
    );

    const status = (consentStatusVS?.asListAll?.() ?? []).find(
      ({ code }) => code === consent?.status
    );

    const scope = consent?.scope?.coding?.[0];

    const category = consent?.category?.[0]?.coding?.[0];

    const sourceReference: Reference = {
      reference: consent?.sourceReference?.reference,
      display: consent.getDocumentTitle(includedResources),
    };

    const verbalConsent = consent?.extension?.
      find((e) => e?.url?.includes('ph-verbal-consent'))?.valueBoolean;

    const createdOn = moment(consent?.dateTime ?? null);
    const startDate = moment(consent?.provision?.period?.start ?? null);
    const endDate = moment(consent?.provision?.period?.end ?? null);
    const provisionType = consent?.provision?.type;

    updateState({
      grantee: grantee.reference ? grantee : consentFormInitialState.grantee,
      selectedOrganization: organization
        ? OrganizationWrapper(organization as Organization)
        : consentFormInitialState.selectedOrganization,
      createdOn: createdOn.isValid() ? createdOn : consentFormInitialState.createdOn,
      startDate: startDate.isValid() ? startDate : consentFormInitialState.startDate,
      endDate: endDate.isValid() ? endDate : consentFormInitialState.endDate,
      status: status ? status : consentFormInitialState.status,
      scope: scope ? (scope as ValueSetComposeIncludeConcept) : consentFormInitialState.scope,
      category: category
        ? (category as ValueSetComposeIncludeConcept)
        : consentFormInitialState.category,
      provisionType: provisionType ? provisionType : consentFormInitialState.provisionType,
      sourceReference: sourceReference?.reference
        ? sourceReference
        : consentFormInitialState.sourceReference,
      isVerbalConsent: verbalConsent
    });
  }, [open, consentData, updateState, consentStatusVS, consentCategoryVS, setDataExternal?.consentType]);

  useEffect(() => {
    getStateFromConsentData();
  }, [getStateFromConsentData]);

  const validateFields = () => {
    const errors: ConsentFormErrorState = {};
    if (startDate?.isValid?.() && endDate?.isValid?.() && startDate.isAfter(endDate, 'date'))
      errors.startDate = i18n('errorMessages.startDateGreater', 'crs');
    updateState({ errors });
    return !Object.keys(errors).length;
  };

  const handleSaveConsent = async () => {
    try {
      if (!validateFields()) return;
      updateState({ isLoading: true });

      const { resource: consent } = consentData ?? {};
      const isEditing = !!consent?.id;

      const resourceType = 'Consent';

      const consentIdentifier = !isEditing
        ? await getResourceUniqueIdentifierPayload(resourceType)
        : null;
      
      const isVerbalConsentExtension = {
        extension: [{
          url: VERBAL_CONSENT,
          valueBoolean: isVerbalConsent
      }]};

      const emptyValue = isEditing ? null : undefined;

      const performer = grantee as ReferenceResources;
      const payload: Partial<Consent> = {
        ...(consent ?? {}),
        resourceType,
        status: (status?.code as Consent['status']) ?? 'active',
        scope: {
          coding: [
            {
              ...scope,
            },
          ],
          text: scope?.display,
        },
        category: [
          {
            coding: [{ ...category }],
            text: category?.display,
          },
        ],
        patient: {
          reference: `Patient/${patient?.id}`,
          display: patient?.getFullName?.() ?? undefined,
        },
        performer: performer
          ? [
              {
                reference: performer?.reference,
                display: performer?.display,
              },
            ]
          : undefined,
        organization: selectedOrganization
          ? [
              {
                reference: `Organization/${selectedOrganization?.id}`,
                display: selectedOrganization?.name,
              },
            ]
          : (emptyValue as undefined),
        dateTime: createdOn?.toISOString?.(),
        provision: {
          type: provisionType as 'deny' | 'permit',
          ...(startDate?.isValid?.() || endDate?.isValid?.()
            ? {
                period: {
                  start: startDate?.isValid?.()
                    ? startDate.startOf('day').toISOString()
                    : undefined,
                  end: endDate?.isValid?.() ? endDate.startOf('day').toISOString() : undefined,
                },
              }
            : {}),
        },

        sourceReference: sourceReference?.reference
          ? {
              reference: sourceReference?.reference,
              display: sourceReference?.display,
            }
          : (emptyValue as undefined),

        policy: [
          {
            authority: 'http://example.org/authority',
            uri: 'http://example.org/policy',
          },
        ],
        ...(isVerbalConsent ? isVerbalConsentExtension : { extension: [] }),
        ...(!isEditing ? { identifier: consentIdentifier ? [consentIdentifier] : undefined } : {}),
      };
      
      if (isEditing) {
        await updateConsent(payload as Consent);
        showSuccessMessage(i18n('patients.details.consents.successEditing', 'crs'));
      } else {
        await createConsent(payload as Consent);
        showSuccessMessage(i18n('patients.details.consents.successAdding', 'crs'));
      }
      onSaveChanges();
      onClose?.({}, 'backdropClick');
    } catch (error) {
      showErrorMessage(i18n('patients.details.consents.errorSaving', 'crs'));
    }

    updateState({ isLoading: false });
  };

  const isAbleToSave = !!(
    status &&
    (scope || setDataExternal?.scope) &&
    (category || setDataExternal?.consentType) &&
    startDate &&
    createdOn &&
    (grantee as ReferenceResources)?.reference
  );

  useEffect(() => {
    onSelectProperties?.(
      isDialogsOpen,
      status?.code ?? '',
      startDate?.toISOString() ?? '',
      endDate?.toISOString() ?? ''
    )
  }, [isDialogsOpen, status, startDate, endDate])

  useEffect(() => { 
    updateState({ 
      isAttachmentsDialogOpen: false, 
      isSearchMemberDialogOpen: false 
    })
  }, [setDataExternal?.consentType])

  return(
    <Grid container sx={{ width: '100%' }} my={3} spacing={2}>
      <Grid item xs={isDialogsOpen ? 6 : 12}>
        <GridSection mt={0}>
          <GridItem>
            <TextField
              fullWidth
              label={i18n('patients.details.consents.subject', 'crs')}
              value={patient?.getFullName?.() ?? ''}
              type="text"
              variant="outlined"
              disabled
            />
          </GridItem>
          <GridItem>
            <Autocomplete
              options={requesterTypes}
              value={(grantee ?? null) as RequesterResourceTypes}
              getOptionLabel={(grantee) => {
                if ((grantee as unknown as ReferenceResources)?.reference)
                  return (grantee as unknown as ReferenceResources)?.display ?? '';

                if (typeof grantee === 'string' && grantee)
                  return i18n(
                    `fhirResource.${grantee[0].toLowerCase()}${grantee.substring(1)}`,
                    'crs'
                  );
                return '';
              }}
              onChange={(_, value: RequesterResourceTypes | ReferenceResources | null) => {
                if (value && value !== RequesterResourceTypes.PATIENT) {
                  updateState({
                    grantee: value,
                    isSearchMemberDialogOpen: true,
                  });
                  return;
                }

                if (value && value === RequesterResourceTypes.PATIENT) {
                  updateState({
                    grantee: {
                      reference: `Patient/${patient?.id}`,
                      display: patient?.getFullName?.() ?? '',
                    },
                    isSearchMemberDialogOpen: false,
                  });
                  return;
                }

                updateState({
                  grantee: value,
                  isSearchMemberDialogOpen: false,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors?.grantee}
                  helperText={errors?.grantee}
                  label={`${i18n('patients.details.consents.grantee', 'crs')}*`}
                  variant="outlined"
                />
              )}
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem>
            <Autocomplete
              value={selectedOrganization}
              fullWidth
              onChange={(_, organization) => {
                updateState({ selectedOrganization: organization });
              }}
              onBlur={() => {
                updateState({
                  organizationInput: '',
                });
              }}
              options={organizations ?? []}
              getOptionLabel={(organization: WrappedOrganization) =>
                capitalize(organization?.name ?? '')
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18n('patients.details.consents.controller', 'crs')}
                  value={organizationInput}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const text = event.target.value;
                    updateState({
                      organizationInput: text,
                    });
                    getOrganizations(text);
                  }}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isOrganizationsLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </GridItem>
          <GridItem>
            <DatePickerMoment
              value={createdOn}
              label={`${i18n('patients.details.consents.createdOn', 'crs')}*`}
              onChange={(value) => {
                updateState({
                  createdOn: value,
                });
              }}
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem>
            <DatePickerMoment
              value={startDate}
              error={errors?.startDate}
              label={`${i18n('patients.details.consents.startDate', 'crs')}`}
              onChange={(value) => {
                updateState({
                  startDate: value,
                  errors: {
                    ...errors,
                    startDate: null,
                  },
                });
              }}
            />
          </GridItem>
          <GridItem>
            <DatePickerMoment
              value={endDate}
              label={i18n('patients.details.consents.endDate', 'crs')}
              onChange={(value) => {
                updateState({
                  endDate: value,
                });
              }}
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem>
            <Autocomplete
              fullWidth
              disabled={!consentStatusVS?.asListAll?.()?.length}
              value={status}
              onChange={(_: React.SyntheticEvent, status) => {
                updateState({ status });
              }}
              options={consentStatusVS?.asListAll?.() ?? []}
              getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors?.status}
                  helperText={errors?.status}
                  label={`${i18n('patients.details.consents.status', 'crs')}*`}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {!consentStatusVS?.asList?.()?.length ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </GridItem>
          {!dontShow && (
            <GridItem>
              <Autocomplete
                fullWidth
                disabled={!consentScopeVS?.asListAll?.()?.length}
                value={scope}
                onChange={(_: React.SyntheticEvent, scope) => {
                  updateState({ scope });
                }}
                options={consentScopeVS?.asListAll?.() ?? []}
                getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors?.scope}
                    helperText={errors?.scope}
                    label={`${i18n('patients.details.consents.scope', 'crs')}*`}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!consentScopeVS?.asListAll?.()?.length ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </GridItem>
          )}
        </GridSection>
          <GridSection>
            {!dontShow && (
              <GridItem>
                <Autocomplete
                  fullWidth
                  disabled={!consentCategoryVS?.asListAll?.()?.length}
                  value={category}
                  onChange={(_: React.SyntheticEvent, category) => {
                    updateState({ category });
                  }}
                  options={consentCategoryVS?.asListAll?.() ?? []}
                  getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors?.category}
                      helperText={errors?.category}
                      label={`${i18n('patients.details.consents.type', 'crs')}*`}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {!consentCategoryVS?.asListAll?.()?.length ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </GridItem>
            )}
            <GridItem sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FormControl>
                <RadioGroup
                  row
                  name="radio-provision-type"
                  value={provisionType}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updateState({
                      provisionType: (event.target as HTMLInputElement).value,
                    });
                  }}
                >
                  {consentProvisionTypes.map(({ code, display }) => (
                    <FormControlLabel
                      key={`radio-provision-type-${code}`}
                      value={code}
                      control={<Radio />}
                      label={display}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </GridItem>
          </GridSection>
        
        <GridSection>
          <GridItem>
            <TextField
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              disabled={isVerbalConsent}
              label={i18n('patients.details.consents.sourceReference', 'crs')}
              value={sourceReference?.display ?? ''}
              type="text"
              variant="outlined"
              onClick={() => {
                updateState({
                  isSearchMemberDialogOpen: false,
                  isAttachmentsDialogOpen: true,
                });
              }}
            />
            {sourceReference && (
              <CancelButton
                sx={{ width: 'auto', marginTop: 0.4, color: '#FF4842' }}
                startIcon={<Close />}
                onClick={() => {
                  updateState({ sourceReference: null });
                }}
                variant="outlined"
                color="error"
              >
                {i18n('patients.details.consents.removeSourceReference', 'crs')}
              </CancelButton>
            )}
          </GridItem>
          <GridItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isVerbalConsent}
                  name="verbalConsent"
                  onChange={() => updateState({ 
                    isVerbalConsent: !isVerbalConsent, 
                    isAttachmentsDialogOpen: false 
                  })}
                />
              }
              label="Is Verbal Consent?"
              sx={{ marginLeft: 1 }}
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem
            xs={12}
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
          >
            <CancelButton
              onClick={() => {
                onClose?.({}, 'backdropClick');
              }}
            />
            <Tooltip
              title={
                !isAbleToSave
                  ? i18n('errorMessages.mustCompleteRequiredFields', 'crs')
                  : i18n('patients.details.consents.saveChanges', 'crs')
              }
            >
              <span>
                <CustomLoadingButton
                  disabled={!isAbleToSave || isLoading}
                  sx={{
                    marginLeft: 1,
                  }}
                  loading={isLoading}
                  onClick={handleSaveConsent}
                  variant="contained"
                >
                  {i18n('patients.details.consents.saveChanges', 'crs')}
                </CustomLoadingButton>
              </span>
            </Tooltip>
          </GridItem>
        </GridSection>
      </Grid>

      {isSearchMemberDialogOpen && (
        <Grid item xs={6} sx={{ marginTop: 0 }}>
          <SearchMember
            patient={patient as WrappedPatient}
            onSelectResource={(resource, includedResources) => {
              const display = getReferenceDisplayByResource(resource, includedResources ?? []);
              updateState({
                grantee: {
                  reference: `${resource.resourceType}/${resource.id}`,
                  display,
                },
                isSearchMemberDialogOpen: false,
              });
            }}
            externalResourceType={grantee as MemberResourceTypes}
          />
        </Grid>
      )}
      {isAttachmentsDialogOpen && (
        <Grid item xs={6} sx={{ marginTop: 0 }}>
          <SelectAttachment
            patient={patient}
            onSelect={(documentReference) => {
              updateState({
                isAttachmentsDialogOpen: false,
                sourceReference: {
                  reference: `DocumentReference/${documentReference.id}`,
                  display: documentReference?.content?.[0]?.attachment?.title ?? '',
                },
              });
            }}
            onClose={() => {
              updateState({ isAttachmentsDialogOpen: false });
            }}
          />
        </Grid>
      )}
    </Grid>
  )
  
}

export default ConsentForm;