import { isString } from 'lodash';
import moment from 'moment';

export const TEXT_CHARACTER_LIMIT = 30;
export const AUTHOR_CHARACTER_LIMIT = 20;
export const DATE_CHARACTER_LIMIT = 10;
export const LIST_CHARACTER_LIMIT = 20;
export const LIST_SIZE = 3;

export const DATE_LIST_FORMAT = 'MMM DD, Y';

export const getFormattedDate = (dateToFormat: string, format = DATE_LIST_FORMAT) => {
  if (dateToFormat && isString(dateToFormat) && dateToFormat.length > 0) {
    try {
      return moment(dateToFormat).format(format);
    } catch (error) {
      console.log(error);
    }
  }
  return 'N/A';
};
