import { rootACL } from '../root/rootAcls';
import { ALL_WILDCARD, EDIT, MENU, VIEW, ADD, DELETE, ASSIGN_PROGRAM, MANUAL_ADD } from './ACL';

interface ACLBase {
  [key: string]: string | ACLBase;
}

interface NotesACLs extends ACLBase {
  ALL: string;
  VIEW: string;
  ADD: string;
}

interface CareTeamACLs extends ACLBase {
  ALL: string;
  VIEW: string;
  ADD: string;
  EDIT: string;
  MEMBERS: {
    ALL: string;
    ADD: string;
    EDIT: string;
    DELETE: string;
  };
}

interface TaskACLs extends ACLBase {
  ALL: string;
  VIEW: string;
  ADD: string;
  EDIT: string;
  ASSIGN_PROGRAM: string;
  NOTES: NotesACLs;
}

interface AttachmentsACLs extends ACLBase {
  ALL: string;
  VIEW: string;
  ADD: string;
  EDIT: string;
}

interface WorkflowACLs extends ACLBase {
  CLOSE: string;
  NEXT: string;
}

const getACLs = <T extends ACLBase>(acls: T) => acls;

const getFlatACLsList = (acls: ACLBase): string[] => {
  const keys = Object.keys(acls);

  return keys.reduce<string[]>((list, key) => {
    if (typeof acls[key] === 'object') return [...list, ...getFlatACLsList(acls[key] as ACLBase)];

    return [...list, acls[key] as string];
  }, []);
};

//Admin ACLs
const adminACL = rootACL.newChild('admin');
//Admin Users ACLs
const adminUsersACL = adminACL.newChild('users');
//Admin Org ACLs
const adminOrganizationsACL = adminACL.newChild('organizations');
const adminOrganizationsChildOrgsACL = adminOrganizationsACL.newChild('childOrganizations');
const adminOrganizationsOwnOrgACL = adminOrganizationsACL.newChild('ownOrganization');
const adminOrganizationsIdentifierDetails = adminOrganizationsACL.newChild('identifierDetails');

//Admin Permissions ACLs
const adminPermissionsACL = adminACL.newChild('permissions');

//CRS ACLs
const crsACL = rootACL.newChild('crs');

//CRS Dashboard
const crsDashboardACL = crsACL.newChild('dashboard');
const crsDashboardDashletACL = crsACL.newChild('dashlet');

//CRS Patient ACLs
const crsPatientACL = crsACL.newChild('patient');
const crsPatientSearchACL = crsPatientACL.newChild('search');
const crsPatientPersonalDetailsACL = crsPatientACL.newChild('personalDetails');
const crsPatientManagingOrganizationACL = crsPatientACL.newChild('managingOrganization');
const crsPatientContactDetailsACL = crsPatientACL.newChild('contactDetails');
const crsPatientAddressDetailsACL = crsPatientACL.newChild('addressDetails');
const crsPatientIdentifierDetailsACL = crsPatientACL.newChild('identifierDetails');
const crsPatientAssessmentsACL = crsPatientACL.newChild('assessments');
const crsPatientInsuranceACL = crsPatientACL.newChild('insurance');
const crsPatientTabsACL = crsPatientACL.newChild('tabs');
const crsPatientCasesACL = crsPatientACL.newChild('cases');
const crsPatientCarePlanACL = crsPatientACL.newChild('carePlan');
const crsPatientServiceRequestACL = crsPatientACL.newChild('serviceRequest');
const crsPatientServiceRequestNotesACL = crsPatientServiceRequestACL.newChild('notes');
const crsPatientImmunizationACL = crsPatientACL.newChild('immunization');
const crsPatientObservationACL = crsPatientACL.newChild('observation');
const crsPatientAppointmentACL = crsPatientACL.newChild('appointment');
const crsPatientConditionACL = crsPatientACL.newChild('condition');
const crsPatientGroupsACL = crsPatientACL.newChild('groups');
const crsPatientGroupsMembersACL = crsPatientGroupsACL.newChild('members');
const crsPatientContactsACL = crsPatientACL.newChild('contacts');
const crsPatientRelatedPersonsACL = crsPatientACL.newChild('relatedPersons');
const crsPatientCareTeamsACL = crsPatientACL.newChild('careTeams');
const crsPatientCareTeamsMembersACL = crsPatientCareTeamsACL.newChild('members');
const crsPatientAttachmentsACL = crsPatientACL.newChild('attachments');
const crsPatientNotesACL = crsPatientACL.newChild('notes');
const crsPatientTasksACL = crsPatientACL.newChild('tasks');
const crsPatientTasksNotesACL = crsPatientTasksACL.newChild('tasks');
const crsPatientNATTaskACL = crsPatientACL.newChild('natTask');
const crsPatientConsentACL = crsPatientACL.newChild('consent');
const crsPatientConsentTasksACL = crsPatientConsentACL.newChild('tasks');
const crsPatientConsentTasksNotesACL = crsPatientConsentTasksACL.newChild('notes');
const crsPatientGeneralPractitionerACL = crsPatientACL.newChild('generalPractitioner');

//CRS Referral
const crsReferralACL = crsACL.newChild('referral');
const crsReferralIdentifierDetailsACL = crsReferralACL.newChild('identifierDetails');
const crsReferralWorkflowACL = crsReferralACL.newChild('workflow');
const crsChildReferralACL = crsReferralACL.newChild('childReferral');

//CRS Case
const crsCaseACL = crsACL.newChild('case');
const crsCaseGoalACL = crsCaseACL.newChild('goal');
const crsCaseGoalNotesACL = crsCaseGoalACL.newChild('notes');
const crsCaseTaskACL = crsCaseACL.newChild('task');
const crsCaseTaskNotesACL = crsCaseTaskACL.newChild('notes');
const crsCaseCareTeamsACL = crsCaseACL.newChild('careTeams');
const crsCaseCareTeamsMembersACL = crsCaseCareTeamsACL.newChild('members');
const crsCaseContactAttemptsACL = crsCaseACL.newChild('contactAttempts');
const crsCaseNotesACL = crsCaseACL.newChild('notes');
const crsCaseAttachmentsACL = crsCaseACL.newChild('attachments');
const crsCaseWorkflowACL = crsCaseACL.newChild('workflow');

//CRS Task
const crsTaskACL = crsACL.newChild('task');
//CRS Data Quality
const dataQuality = rootACL.newChild('dataQuality')
const dataQualityIncidentACL = dataQuality.newChild('incident');
//CRS Practitioner
const crsPractitionerACL = crsACL.newChild('practitioner');
const crsPractitionerPersonalDetailsACL = crsPractitionerACL.newChild('personalDetails');
const crsPractitionerContactDetailsACL = crsPractitionerACL.newChild('contactDetails');
const crsPractitionerIdentifierDetailsACL = crsPractitionerACL.newChild('identifierDetails');
const crsPractitionerLanguageDetailsACL = crsPractitionerACL.newChild('languageDetails');
const crsPractitionerAddressDetailsACL = crsPractitionerACL.newChild('addressDetails');
const crsPractitionerRolesACL = crsPractitionerACL.newChild('roles');

//YoCare ACLs
const yoCareACL = rootACL.newChild('yoCare');
//YoCare Users ACLs
const yoCareUsersACL = yoCareACL.newChild('users');
//YoCare Persons ACLs
const yoCarePersonsACL = yoCareACL.newChild('persons');
//YoCare DataSystems ACLs
const yoCareDataSystemsACL = yoCareACL.newChild('dataSystems');

//Engagement HUB
const engagementHubACL = rootACL.newChild('engagementHub');
const engagementHubPatientEngagementACL = engagementHubACL.newChild('patientEngagement');
const engagementHubPatientEngagementNotesACL = engagementHubPatientEngagementACL.newChild('notes');
const engagementHubPatientEngagementAttachmentsACL = 
  engagementHubPatientEngagementACL.newChild('attachments');
const engagementHubPatientEngagementWorkflowACL =
  engagementHubPatientEngagementACL.newChild('workflow');

const crsAcls = {
  ALL: ALL_WILDCARD,
  ADMIN: {
    ALL: adminACL.concat(ALL_WILDCARD),
    USERS: {
      ALL: adminUsersACL.concat(ALL_WILDCARD),
      MENU: adminUsersACL.concat(MENU),
      VIEW: adminUsersACL.concat(VIEW),
      ADD: adminUsersACL.concat(ADD),
      ALL_ORGANIZATION: adminUsersACL.concat('allOrganization'),
      OWN_ORGANIZATION:  adminUsersACL.concat('ownOrganization'),
      OWN_AND_CHILD_ORGANIZATION: adminUsersACL.concat('ownAndChilOrganization'),
    },
    ORGANIZATIONS: {
      ALL: adminOrganizationsACL.concat(ALL_WILDCARD),
      MENU: adminOrganizationsACL.concat(MENU),
      ADD: adminOrganizationsACL.concat(ADD),
      EDIT: adminOrganizationsACL.concat(EDIT),
      DELETE: adminOrganizationsACL.concat(DELETE),
      CHILD_ORGANIZATIONS: {
        VIEW: adminOrganizationsChildOrgsACL.concat(VIEW),
      },
      OWN_ORGANIZATION: {
        VIEW: adminOrganizationsOwnOrgACL.concat(VIEW),
      },
      IDENTIFIER_DETAILS: {
        ALL: adminOrganizationsIdentifierDetails.concat(ALL_WILDCARD),
        VIEW: adminOrganizationsIdentifierDetails.concat(VIEW),
        ADD: adminOrganizationsIdentifierDetails.concat(ADD),
        EDIT: adminOrganizationsIdentifierDetails.concat(EDIT),
        DELETE: adminOrganizationsIdentifierDetails.concat(DELETE),
      },
    },
    PERMISSIONS: {
      ALL: adminPermissionsACL.concat(ALL_WILDCARD),
      MENU: adminPermissionsACL.concat(MENU),
      ADD: adminPermissionsACL.concat(ADD),
      DELETE: adminPermissionsACL.concat(DELETE),
      EDIT: adminPermissionsACL.concat(EDIT),
    },
  },
  CRS: {
    ALL: crsACL.concat(ALL_WILDCARD),
    DASHBOARD: {
      ALL: crsDashboardACL.concat(ALL_WILDCARD),
      MENU: crsDashboardACL.concat(MENU),
      DASHLET: {
        MY_TASKS: crsDashboardDashletACL.concat('myTasks', VIEW),
        MY_PATIENTS: crsDashboardDashletACL.concat('myPatients', VIEW),
        MY_PATIENT_ENGAGEMENTS: crsDashboardDashletACL.concat('myPatientEngagements', VIEW),
        MY_ORGANIZATION_PATIENTS: crsDashboardDashletACL.concat('myOrganizationPatients', VIEW),
      }
    },
    PATIENT: {
      ALL: crsPatientACL.concat(ALL_WILDCARD),
      MENU: crsPatientACL.concat(MENU),
      ADD: crsPatientACL.concat(ADD),
      MANUAL_ADD: crsPatientACL.concat(MANUAL_ADD),
      VIEW: crsPatientACL.concat(VIEW),
      TABS: {
        ALL: crsPatientTabsACL.concat(ALL_WILDCARD),
        GENERAL: crsPatientTabsACL.concat('general', VIEW),
        REFERRAL_CASES: crsPatientTabsACL.concat('referralAndCases', VIEW),
        HEALTH: crsPatientTabsACL.concat('health', VIEW),
        GROUPS: crsPatientTabsACL.concat('groups', VIEW),
        INSURANCE: crsPatientTabsACL.concat('insurance', VIEW),
        DOCUMENTS: crsPatientTabsACL.concat('documents', VIEW),
        TASKS: crsPatientTabsACL.concat('tasks', VIEW),
        ENGAGEMENT_HUB: crsPatientTabsACL.concat('engagement_hub', VIEW),
        CONSENTS: crsPatientTabsACL.concat('consents', VIEW),
      },
      CARD: {
        VIEW: crsPatientACL.concat('card', VIEW),
      },
      SIL: {
        VIEW: crsPatientACL.concat('sil', VIEW),
      },
      PRS: {
        VIEW: crsPatientACL.concat('prs', VIEW),
      },
      NAT_TASK: {
        ANSWER_TASK: crsPatientNATTaskACL.concat('answerTask'),
        PRINT_TASK: crsPatientNATTaskACL.concat('printTask'),
        CLOSE_REQUEST: crsPatientNATTaskACL.concat('closeRequest'),
      },
      GROUPS: {
        ALL: crsPatientGroupsACL.concat(ALL_WILDCARD),
        VIEW: crsPatientGroupsACL.concat(VIEW),
        ADD: crsPatientGroupsACL.concat(ADD),
        EDIT: crsPatientGroupsACL.concat(EDIT),
        DELETE: crsPatientGroupsACL.concat(DELETE),
        MEMBERS: {
          ALL: crsPatientGroupsMembersACL.concat(ALL_WILDCARD),
          ADD: crsPatientGroupsMembersACL.concat(ADD),
          EDIT: crsPatientGroupsMembersACL.concat(EDIT),
          DELETE: crsPatientGroupsMembersACL.concat(DELETE),
        },
      },
      TASKS: getACLs<TaskACLs>({
        ALL: crsPatientTasksACL.concat(ALL_WILDCARD),
        VIEW: crsPatientTasksACL.concat(VIEW),
        ADD: crsPatientTasksACL.concat(ADD),
        EDIT: crsPatientTasksACL.concat(EDIT),
        ASSIGN_PROGRAM: crsPatientTasksACL.concat(ASSIGN_PROGRAM),
        NOTES: getACLs<NotesACLs>({
          ALL: crsPatientTasksNotesACL.concat(ALL_WILDCARD),
          VIEW: crsPatientTasksNotesACL.concat(VIEW),
          ADD: crsPatientTasksNotesACL.concat(ADD),
        }),
      }),
      NOTES: getACLs<NotesACLs>({
        ALL: crsPatientNotesACL.concat(ALL_WILDCARD),
        VIEW: crsPatientNotesACL.concat(VIEW),
        ADD: crsPatientNotesACL.concat(ADD),
      }),
      ATTACHMENTS: getACLs<AttachmentsACLs>({
        ALL: crsPatientAttachmentsACL.concat(ALL_WILDCARD),
        VIEW: crsPatientAttachmentsACL.concat(VIEW),
        ADD: crsPatientAttachmentsACL.concat(ADD),
        EDIT: crsPatientAttachmentsACL.concat(EDIT),
      }),
      CARE_TEAMS: getACLs<CareTeamACLs>({
        ALL: crsPatientCareTeamsACL.concat(ALL_WILDCARD),
        VIEW: crsPatientCareTeamsACL.concat(VIEW),
        ADD: crsPatientCareTeamsACL.concat(ADD),
        EDIT: crsPatientCareTeamsACL.concat(EDIT),
        MEMBERS: {
          ALL: crsPatientCareTeamsMembersACL.concat(ALL_WILDCARD),
          ADD: crsPatientCareTeamsMembersACL.concat(ADD),
          EDIT: crsPatientCareTeamsMembersACL.concat(EDIT),
          DELETE: crsPatientCareTeamsMembersACL.concat(DELETE),
        },
      }),
      CONTACTS: {
        ALL: crsPatientContactsACL.concat(ALL_WILDCARD),
        VIEW: crsPatientContactsACL.concat(VIEW),
        ADD: crsPatientContactsACL.concat(ADD),
        EDIT: crsPatientContactsACL.concat(EDIT),
      },
      RELATED_PERSONS: {
        ALL: crsPatientRelatedPersonsACL.concat(ALL_WILDCARD),
        VIEW: crsPatientRelatedPersonsACL.concat(VIEW),
        ADD: crsPatientRelatedPersonsACL.concat(ADD),
        EDIT: crsPatientRelatedPersonsACL.concat(EDIT),
      },
      CONDITION: {
        ALL: crsPatientConditionACL.concat(ALL_WILDCARD),
        VIEW: crsPatientConditionACL.concat(VIEW),
        ADD: crsPatientConditionACL.concat(ADD),
        EDIT: crsPatientConditionACL.concat(EDIT),
      },
      EPISODE_OF_CARE: {
        ALL: crsPatientConditionACL.concat(ALL_WILDCARD),
        VIEW: crsPatientConditionACL.concat(VIEW),
        ADD: crsPatientConditionACL.concat(ADD),
        EDIT: crsPatientConditionACL.concat(EDIT),
      },
      APPOINTMENT: {
        ALL: crsPatientAppointmentACL.concat(ALL_WILDCARD),
        VIEW: crsPatientAppointmentACL.concat(VIEW),
        ADD: crsPatientAppointmentACL.concat(ADD),
        EDIT: crsPatientAppointmentACL.concat(EDIT),
      },
      OBSERVATION: {
        ALL: crsPatientObservationACL.concat(ALL_WILDCARD),
        VIEW: crsPatientObservationACL.concat(VIEW),
        ADD: crsPatientObservationACL.concat(ADD),
        EDIT: crsPatientObservationACL.concat(EDIT),
      },
      IMMUNIZATION: {
        ALL: crsPatientImmunizationACL.concat(ALL_WILDCARD),
        VIEW: crsPatientImmunizationACL.concat(VIEW),
        ADD: crsPatientImmunizationACL.concat(ADD),
      },
      CASES: {
        ALL: crsPatientCasesACL.concat(ALL_WILDCARD),
        VIEW: crsPatientCasesACL.concat(VIEW),
        EDIT: crsPatientCasesACL.concat(EDIT),
      },
      CARE_PLAN: {
        ALL: crsPatientCarePlanACL.concat(ALL_WILDCARD),
        VIEW: crsPatientCarePlanACL.concat(VIEW),
        EDIT: crsPatientCarePlanACL.concat(EDIT),
      },
      SERVICE_REQUESTS: {
        ALL: crsPatientServiceRequestACL.concat(ALL_WILDCARD),
        VIEW: crsPatientServiceRequestACL.concat(VIEW),
        EDIT: crsPatientServiceRequestACL.concat(EDIT),
        ADD: crsPatientServiceRequestACL.concat(ADD),
        NOTES: getACLs<NotesACLs>({
          ALL: crsPatientServiceRequestNotesACL.concat(ALL_WILDCARD),
          VIEW: crsPatientServiceRequestNotesACL.concat(VIEW),
          ADD: crsPatientServiceRequestNotesACL.concat(ADD),
        }),
      },
      SEARCH: {
        ALL: crsPatientSearchACL.concat(ALL_WILDCARD),
        SIMPLE: crsPatientSearchACL.concat('simple'),
        ADVANCED: crsPatientSearchACL.concat('advanced'),
      },
      PERSONAL_DETAILS: {
        ALL: crsPatientPersonalDetailsACL.concat(ALL_WILDCARD),
        VIEW: crsPatientPersonalDetailsACL.concat(VIEW),
        EDIT: crsPatientPersonalDetailsACL.concat(EDIT),
      },
      MANAGING_ORGANIZATION: {
        EDIT: crsPatientManagingOrganizationACL.concat(EDIT),
      },
      CONTACT_DETAILS: {
        ALL: crsPatientContactDetailsACL.concat(ALL_WILDCARD),
        VIEW: crsPatientContactDetailsACL.concat(VIEW),
        ADD: crsPatientContactDetailsACL.concat(ADD),
        EDIT: crsPatientContactDetailsACL.concat(EDIT),
        DELETE: crsPatientContactDetailsACL.concat(DELETE),
      },
      ADDRESS_DETAILS: {
        ALL: crsPatientAddressDetailsACL.concat(ALL_WILDCARD),
        VIEW: crsPatientAddressDetailsACL.concat(VIEW),
        ADD: crsPatientAddressDetailsACL.concat(ADD),
        EDIT: crsPatientAddressDetailsACL.concat(EDIT),
        DELETE: crsPatientAddressDetailsACL.concat(DELETE),
      },
      IDENTIFIER_DETAILS: {
        ALL: crsPatientIdentifierDetailsACL.concat(ALL_WILDCARD),
        VIEW: crsPatientIdentifierDetailsACL.concat(VIEW),
        ADD: crsPatientIdentifierDetailsACL.concat(ADD),
        EDIT: crsPatientIdentifierDetailsACL.concat(EDIT),
        DELETE: crsPatientIdentifierDetailsACL.concat(DELETE),
      },
      ASSESSMENTS: {
        ALL: crsPatientAssessmentsACL.concat(ALL_WILDCARD),
        START: crsPatientAssessmentsACL.concat('start'),
        VIEW: crsPatientAssessmentsACL.concat(VIEW),
      },
      INSURANCE: {
        ALL: crsPatientInsuranceACL.concat(ALL_WILDCARD),
        ADD: crsPatientInsuranceACL.concat(ADD),
        EDIT: crsPatientInsuranceACL.concat(EDIT),
      },
      GENERAL_PRACTITIONER: {
        VIEW: crsPatientGeneralPractitionerACL.concat(VIEW),
        ADD: crsPatientGeneralPractitionerACL.concat(ADD),
        DELETE: crsPatientGeneralPractitionerACL.concat(DELETE),
      },
      CONSENT: {
        ADD: crsPatientConsentACL.concat(ADD),
        EDIT: crsPatientConsentACL.concat(EDIT),
        TASKS: getACLs<TaskACLs>({
          ALL: crsPatientConsentTasksACL.concat(ALL_WILDCARD),
          VIEW: crsPatientConsentTasksACL.concat(VIEW),
          ADD: crsPatientConsentTasksACL.concat(ADD),
          EDIT: crsPatientConsentTasksACL.concat(EDIT),
          ASSIGN_PROGRAM: crsPatientConsentTasksACL.concat(ASSIGN_PROGRAM),
          NOTES: getACLs<NotesACLs>({
            ALL: crsPatientConsentTasksNotesACL.concat(ALL_WILDCARD),
            VIEW: crsPatientConsentTasksNotesACL.concat(VIEW),
            ADD: crsPatientConsentTasksNotesACL.concat(ADD),
          }),
        }),
      },
    },
    REFERRAL: {
      ALL: crsReferralACL.concat(ALL_WILDCARD),
      MENU: crsReferralACL.concat(MENU),
      EDIT: crsReferralACL.concat(EDIT),
      IDENTIFIER_DETAILS: {
        ALL: crsReferralIdentifierDetailsACL.concat(ALL_WILDCARD),
        VIEW: crsReferralIdentifierDetailsACL.concat(VIEW),
        ADD: crsReferralIdentifierDetailsACL.concat(ADD),
        EDIT: crsReferralIdentifierDetailsACL.concat(EDIT),
        DELETE: crsReferralIdentifierDetailsACL.concat(DELETE),
      },
      WORKFLOW: getACLs<WorkflowACLs>({
        CLOSE: crsReferralWorkflowACL.concat('close'),
        NEXT: crsReferralWorkflowACL.concat('next'),
      }),
      CHILD_REFERRAL: {
        EDIT: crsChildReferralACL.concat(EDIT),
      },
    },
    CASE: {
      ALL: crsCaseACL.concat(ALL_WILDCARD),
      VIEW: crsCaseACL.concat(VIEW),
      MENU: crsCaseACL.concat(MENU),
      EDIT: crsCaseACL.concat(EDIT),
      GOAL: {
        ALL: crsCaseGoalACL.concat(ALL_WILDCARD),
        VIEW: crsCaseGoalACL.concat(VIEW),
        ADD: crsCaseGoalACL.concat(ADD),
        EDIT: crsCaseGoalACL.concat(EDIT),
        NOTES: getACLs<NotesACLs>({
          ALL: crsCaseGoalNotesACL.concat(ALL_WILDCARD),
          VIEW: crsCaseGoalNotesACL.concat(VIEW),
          ADD: crsCaseGoalNotesACL.concat(ADD),
        }),
      },
      TASKS: getACLs<TaskACLs>({
        ALL: crsCaseTaskACL.concat(ALL_WILDCARD),
        VIEW: crsCaseTaskACL.concat(VIEW),
        ADD: crsCaseTaskACL.concat(ADD),
        EDIT: crsCaseTaskACL.concat(EDIT),
        ASSIGN_PROGRAM: crsCaseTaskACL.concat(ASSIGN_PROGRAM),
        NOTES: getACLs<NotesACLs>({
          ALL: crsCaseTaskNotesACL.concat(ALL_WILDCARD),
          VIEW: crsCaseTaskNotesACL.concat(VIEW),
          ADD: crsCaseTaskNotesACL.concat(ADD),
        }),
      }),
      CARE_TEAMS: getACLs<CareTeamACLs>({
        ALL: crsCaseCareTeamsACL.concat(ALL_WILDCARD),
        VIEW: crsCaseCareTeamsACL.concat(VIEW),
        ADD: crsCaseCareTeamsACL.concat(ADD),
        EDIT: crsCaseCareTeamsACL.concat(EDIT),
        MEMBERS: {
          ALL: crsCaseCareTeamsMembersACL.concat(ALL_WILDCARD),
          ADD: crsCaseCareTeamsMembersACL.concat(ADD),
          EDIT: crsCaseCareTeamsMembersACL.concat(EDIT),
          DELETE: crsCaseCareTeamsMembersACL.concat(DELETE),
        },
      }),
      CONTACT_ATTEMPTS: {
        ALL: crsCaseContactAttemptsACL.concat(ALL_WILDCARD),
        VIEW: crsCaseContactAttemptsACL.concat(VIEW),
        ADD: crsCaseContactAttemptsACL.concat(ADD),
      },
      NOTES: getACLs<NotesACLs>({
        ALL: crsCaseNotesACL.concat(ALL_WILDCARD),
        VIEW: crsCaseNotesACL.concat(VIEW),
        ADD: crsCaseNotesACL.concat(ADD),
      }),
      ATTACHMENTS: getACLs<AttachmentsACLs>({
        ALL: crsCaseAttachmentsACL.concat(ALL_WILDCARD),
        VIEW: crsCaseAttachmentsACL.concat(VIEW),
        ADD: crsCaseAttachmentsACL.concat(ADD),
        EDIT: crsCaseAttachmentsACL.concat(EDIT),
      }),
      WORKFLOW: getACLs<WorkflowACLs>({
        CLOSE: crsCaseWorkflowACL.concat('close'),
        NEXT: crsCaseWorkflowACL.concat('next'),
      }),
    },
    TASK: {
      ALL: crsTaskACL.concat(ALL_WILDCARD),
      MENU: crsTaskACL.concat(MENU),
    },
    PRACTITIONER: {
      ALL: crsPractitionerACL.concat(ALL_WILDCARD),
      MENU: crsPractitionerACL.concat(MENU),
      MANUAL_ADD: crsPractitionerACL.concat('manualAdd'),
      ADD: crsPractitionerACL.concat(ADD),
      VIEW: crsPractitionerACL.concat(VIEW),
      ROLES: {
        ADD: crsPractitionerRolesACL.concat(ADD),

        DELETE: crsPractitionerRolesACL.concat(DELETE),
      },
      PERSONAL_DETAILS: {
        EDIT: crsPractitionerPersonalDetailsACL.concat(EDIT),
      },
      CONTACT_DETAILS: {
        ADD: crsPractitionerContactDetailsACL.concat(ADD),
        EDIT: crsPractitionerContactDetailsACL.concat(EDIT),
        DELETE: crsPractitionerContactDetailsACL.concat(DELETE),
      },
      IDENTIFIER_DETAILS: {
        ADD: crsPractitionerIdentifierDetailsACL.concat(ADD),
        EDIT: crsPractitionerIdentifierDetailsACL.concat(EDIT),
        DELETE: crsPractitionerIdentifierDetailsACL.concat(DELETE),
      },
      LANGUAGE_DETAILS: {
        ADD: crsPractitionerLanguageDetailsACL.concat(ADD),
        EDIT: crsPractitionerLanguageDetailsACL.concat(EDIT),
        DELETE: crsPractitionerLanguageDetailsACL.concat(DELETE),
      },
      ADDRESS_DETAILS: {
        ADD: crsPractitionerAddressDetailsACL.concat(ADD),
        EDIT: crsPractitionerAddressDetailsACL.concat(EDIT),
        DELETE: crsPractitionerAddressDetailsACL.concat(DELETE),
      },
    },
  },
  YOCARE: {
    ALL: yoCareACL.concat(ALL_WILDCARD),
    USERS: {
      ALL: yoCareUsersACL.concat(ALL_WILDCARD),
      MENU: yoCareUsersACL.concat(MENU),
    },
    PERSONS: {
      ALL: yoCarePersonsACL.concat(ALL_WILDCARD),
      MENU: yoCarePersonsACL.concat(MENU),
    },
    DATASYSTEMS: {
      ALL: yoCareDataSystemsACL.concat(ALL_WILDCARD),
      MENU: yoCareDataSystemsACL.concat(MENU),
    },
  },
  ENGAGEMENT_HUB: {
    ALL: engagementHubACL.concat(ALL_WILDCARD),
    PATIENT_ENGAGEMENT: {
      ALL: engagementHubPatientEngagementACL.concat(ALL_WILDCARD),
      MENU: engagementHubPatientEngagementACL.concat(MENU),
      VIEW: engagementHubPatientEngagementACL.concat(VIEW),
      ADD: engagementHubPatientEngagementACL.concat(ADD),
      EDIT: engagementHubPatientEngagementACL.concat(EDIT),
      NOTES: getACLs<NotesACLs>({
        ALL: engagementHubPatientEngagementNotesACL.concat(ALL_WILDCARD),
        VIEW: engagementHubPatientEngagementNotesACL.concat(VIEW),
        ADD: engagementHubPatientEngagementNotesACL.concat(ADD),
      }),
      ATTACHMENTS: getACLs<AttachmentsACLs>({
        ALL: engagementHubPatientEngagementAttachmentsACL.concat(ALL_WILDCARD),
        VIEW: engagementHubPatientEngagementAttachmentsACL.concat(VIEW),
        ADD: engagementHubPatientEngagementAttachmentsACL.concat(ADD),
        EDIT: engagementHubPatientEngagementAttachmentsACL.concat(EDIT),
      }),
      WORKFLOW: getACLs<WorkflowACLs>({
        CLOSE: engagementHubPatientEngagementWorkflowACL.concat('close'),
        NEXT: engagementHubPatientEngagementWorkflowACL.concat('next'),
      }),
    },
  },
  PROVIDER_WINDOW: {
    VIEW: 'crs.provider_window.view',
    OPEN_NON_EXISTENT_PATIENT: 'crs.provider_window.open_non_existent_patient',
  },
  DATA_QUALITY: {
    ALL: dataQuality.concat(ALL_WILDCARD),
    INCIDENT: {
      ALL: dataQualityIncidentACL.concat(ALL_WILDCARD),
      MENU: dataQualityIncidentACL.concat(MENU),
      EDIT: dataQualityIncidentACL.concat(EDIT),
    },
  },
};

const getACLsTemplateList = (): [string, string][] => {
  const aclsList = getFlatACLsList(crsAcls);
  const actions = [ADD, EDIT, VIEW, DELETE];
  return aclsList.map((acl) => {
    let description = '';
    const parts = acl.split('.');
    switch (parts[0]) {
      case 'admin':
        description = `Admin`;
        break;
      case 'crs':
        description = `Care Coordination`;
        break;
      case 'yoCare':
        description = `Yo Care`;
        break;
      case 'engagementHub':
        description = `Engagement Hub`;
        break;
      default:
        description = `All Access`;
        break;
    }
    if (parts.length <= 1) return [description, acl];
    const remainingParts = parts.slice(1);
    const lastPart = remainingParts[remainingParts.length - 1];

    if (actions.includes(lastPart) || lastPart === ALL_WILDCARD) {
      remainingParts.splice(0, 0, lastPart);
      remainingParts.pop();
    }
    description += ` -${remainingParts.reduce((concatenation, part) => {
      if (part === ALL_WILDCARD)
        return `${concatenation} All Access${remainingParts.length > 1 ? ' to' : ''}`;
      return `${concatenation} ${part.replace(/(?<!^)([A-Z])/g, ' $1')}`;
    }, '')}`;
    return [description.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()), acl];
  });
};

export default crsAcls;
