import { Box, Button, Card, Dialog, DialogActions, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, startCase } from "lodash";
import { useEffect, useState } from "react";
import { Observation } from "src/@nicheaim/fhir-base/mappings/Observation";
import { WrappedObservation } from "src/@nicheaim/fhir-base/wrappers/Observation";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { useValueSet } from "src/@nicheaim/fhir-react";
import RegistryComponent, { ValueType } from "src/components/RegistryComponent";
import useAddEntityRequestStates from "src/hooks/useAddEntityRequestStates";
import useLocales from "src/hooks/useLocales";
import useTenantConfigData from "src/hooks/useTenantConfigData";
import { ValueSetComposeIncludeConcept } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { convertValueToValueSet } from "src/sections/crs/common/common-utils";
import { getRegistries, RegistryValueType } from "src/services/api/registry";

type Props = {
  patient: WrappedPatient | null;
  observation?: WrappedObservation | null;
  open: boolean;
  onClose: VoidFunction;
  handleObservation: (data: any) => Promise<any>;
};

export function ObservationForm({ patient, open, observation, onClose, handleObservation }: Props) {
  const { i18n } = useLocales();
  const { componentsData } = useTenantConfigData();

  const [valueObservation, setValueObservation] = useState<{
    valueType: RegistryValueType | null;
    value: ValueType;
  }>({ valueType: null, value: null });
  const [typeObservation, setTypeObservation] = useState('');
  const [categoryObservation, setCategoryObservation] = useState('');

  const [valueSetType] = useValueSet('ph-type-observation', { map: ValueSetWrapper });
  const [valueSetCategory] = useValueSet('ph-category-observation', { map: ValueSetWrapper });
  const [observationsCatalog] = useValueSet('ph-observations-catalog', { map: ValueSetWrapper });

  const [{ error }, { setError }] = useAddEntityRequestStates();

  useEffect(() => {
    if (isEmpty(observation)) {
      setCategoryObservation(componentsData.observation?.defaultValues?.category?.code ?? '');
      setTypeObservation('');
      return;
    };
    setCategoryObservation(observation?.category?.[0]?.coding?.[0]?.code ?? '');
    setTypeObservation(observation?.code?.coding?.[0].code ?? '');
  }, [observation, componentsData]);

  const getValue = () => {
    const value =
      typeof valueObservation.value === 'object'
        ? valueObservation.value?.code
        : valueObservation.value;
    if (!value) return null;
    switch (valueObservation?.valueType) {
      case RegistryValueType.CodeableConcept:
        if (valueObservation.value !== null && typeof valueObservation.value === 'object') {
          const { ...valueWithoutCategory } = valueObservation.value;

          return {
            valueCodeableConcept: {
              coding: [valueWithoutCategory],
              text: valueObservation.value?.display,
            },
          };
        }
        return null;
      case RegistryValueType.NUMBER: {
        const integer = parseInt(String(value));
        if (isNaN(integer)) return null;
        return { valueInteger: integer };
      }
      default: {
        return { valueString: value };
      }
    }
  };

  const { data: registries } = useQuery({
    queryKey: ['registries', { groupIdentifier: 'observations' }],
    queryFn: async () => {
      const registries = await getRegistries('observations');
      return registries.data;
    },
  });

  const validateRequiredFields = (value: any) => {
    if (isEmpty(typeObservation) || isEmpty(value) || isEmpty(categoryObservation)) {
      return false;
    }
    return true;
  };

  const mapObservation = (derivedFrom?: string) => {
    const value = getValue() ?? {};

    if (!validateRequiredFields(value)) return setError('Please, fill all required fields');
    const conceptList = valueSetType?.asListAll?.() ?? [];

    const valueSetConcept = conceptList.find(({ code }) => code === typeObservation);

    let observationRes: Observation = {
      resourceType: 'Observation',
      code: {
        coding: [
          {
            code: valueSetConcept?.code,
            display: valueSetConcept?.display,
            system: valueSetType?.getConceptSystem?.(),
          },
        ],
        text: valueSetConcept?.display,
      },
      effectiveDateTime: new Date().toISOString(),
      issued: new Date().toISOString(),
      status: 'preliminary',
      subject: {
        reference: `Patient/${patient?.id}`,
      },
      ...value,
      ...(derivedFrom && {
        derivedFrom: [
          {
            reference: `Observation/${derivedFrom}`,
            type: 'Observation',
          },
        ],
      }),
    };

    if (categoryObservation) {
      const catCode = convertValueToValueSet(categoryObservation, valueSetCategory);

      if (catCode) {
        observationRes.category = [
          {
            coding: [catCode],
            text: startCase(catCode?.display),
          },
        ];
      }
    }

    return observationRes;
  };

  const save = () => {
    const edit = observation ? observation?.id : undefined;
    const mappedObservation = mapObservation(edit);
    handleObservation(mappedObservation);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>
        {!isEmpty(observation)
          ? `Edit ${i18n('patients.details.observations.title', 'crs')}`
          : `Add ${i18n('patients.details.observations.title', 'crs')}`}{' '}
      </DialogTitle>
      <Card sx={{ m: 2 }}>
        <Grid container>
          <Grid item xs={12} >
            <Stack spacing={2} sx={{ p: 2 }}>
              <FormControl sx={{ mr: 2 }} fullWidth error={!!error}>
                <InputLabel id="category">
                  {i18n('patients.details.observations.category', 'crs')}
                </InputLabel>
                <Select
                  label="category"
                  value={categoryObservation}
                  fullWidth
                  onChange={(event) => {
                    setCategoryObservation(event.target.value);
                    setError(null);
                  }}
                >
                  {(
                    valueSetCategory?.asListAll?.() ?? ([] as ValueSetComposeIncludeConcept[])
                  ).map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ mr: 2 }} fullWidth error={!!error}>
                <InputLabel id="observation">
                  {i18n('patients.details.observations.observations', 'crs')}
                </InputLabel>
                <Select
                  label="observation"
                  value={typeObservation}
                  fullWidth
                  onChange={(event) => {
                    setTypeObservation(event.target.value);
                    setError(null);
                  }}
                >
                  {(
                    valueSetType?.asListAll?.() ?? ([] as ValueSetComposeIncludeConcept[])
                  ).map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <RegistryComponent
                disabled={!typeObservation}
                registries={registries ?? []}
                valueSet={observationsCatalog?.asListAll() ?? []}
                label={i18n('patients.details.observations.value', 'crs')}
                keyRegistry={typeObservation}
                onValueChange={(value, valueType) => {
                  setValueObservation({ value, valueType });
                  setError(null);
                }}
                error={error}
              />
            </Stack>
          </Grid>
          {error && (
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <FormHelperText sx={{ color: '#d50000' }}>{error}</FormHelperText>
            </Grid>
          )}
        </Grid>
        <Stack spacing={2} alignItems="center">
          <DialogActions>
            <Box sx={{ flexGrow: 1 }} />
            <Button variant="contained" color="info" onClick={onClose}>
              {i18n('cancel')}
            </Button>

            <Button
              variant="contained"
              color="info"
              type="submit"
              onClick={save}
            >
              {i18n('submit')}
            </Button>
          </DialogActions>
        </Stack>
      </Card>
    </Dialog>
  )
}