import React, { ChangeEvent } from 'react';
import { GridItem, GridSection } from '../../../components/CustomModal';
import { Box, Divider, Grid, TextField } from '@mui/material';
import { Typography } from '@mui/material';
import useTranslations from '../../../hooks/useTranslations';
import useLocales from '../../../hooks/useLocales';
import { AnnotationsModalDetailForm } from './AnnotationsModal';
import { FormikErrors, FormikTouched } from 'formik';
import { scrollbarStyle } from '../../../utils/utilities';
import { getFormattedDate } from './annotation-utils';

interface AnnotationsModalDetailProps {
  createMode: boolean;
  formValues: AnnotationsModalDetailForm;
  formTouched: FormikTouched<AnnotationsModalDetailForm>;
  formErrors: FormikErrors<AnnotationsModalDetailForm>;
  formHandleChange: {
    (e: ChangeEvent<any>): void;
    <T_1 = string | ChangeEvent<any>>(field: T_1): T_1 extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  selectedAnnotation: any;
}

const AnnotationsModalDetail = ({
  createMode,
  formValues,
  formTouched,
  formErrors,
  formHandleChange,
  selectedAnnotation,
}: AnnotationsModalDetailProps) => {
  const { i18n } = useLocales();

  return (
    <Grid container sx={{ overflow: 'none' }} flexDirection={'column'} xs={12}>
      <Grid
        container
        item
        xs={2}
        sx={{
          // border: 1,
          // borderColor: 'red',
          minWidth: '100%',
          maxWidth: '100%',
          maxHeight: '100%',
        }}
        px={3}
        py={3}
      >
        <Grid item xs={8} flexDirection={'row'}>
          <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[500] }}>{`${i18n(
            'annotations.details.author',
            'crs'
          )} `}</Typography>
          {selectedAnnotation ? (
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[900] }}>
              {selectedAnnotation?.authorString}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={4} flexDirection={'row'} sx={{ textAlign: 'left' }}>
          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.palette.grey[500], marginRight: 'auto' }}
          >
            {`${i18n('annotations.details.date', 'crs')} `}
          </Typography>
          {selectedAnnotation ? (
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[900] }}>
              {getFormattedDate(selectedAnnotation?.time)}
            </Typography>
          ) : null}
        </Grid>
        {/* <Grid item xs={12} mt={0} pt={0}>
          <Divider sx={{ borderStyle: 'dashed', marginBottom: 0, paddingBottom: 0 }} />
        </Grid> */}
      </Grid>

      <Grid
        container
        item
        xs={10}
        sx={{
          borderTop: 1,
          borderTopStyle: 'dashed',
          borderTopColor: (theme) => `${theme.palette.grey[300]}`,
          // border: 1,
          // borderColor: 'blue',
          // borderStyle: 'dashed',
          // border: 1,
          // borderColor: 'blue',
          minWidth: '100%',
          maxWidth: '100%',
          maxHeight: '100%',
          overflowY: 'scroll',
          overflowX: 'none',
          ...scrollbarStyle,
        }}
        p={1}
      >
        {createMode ? (
          <Grid item xs={12}>
            <TextField
              id="text"
              name="text"
              // sx={{ minHeight: '100%', height: '100%' }}
              InputProps={{
                sx: {
                  height: '100%',
                  '& fieldset': { border: 'none' },
                },
              }}
              minRows={30}
              error={formTouched.text && !!formErrors.text}
              helperText={formTouched.text && formErrors.text}
              fullWidth
              multiline
              placeholder={`${i18n('annotations.details.bodyPlaceholder', 'crs')}`}
              value={formValues.text}
              onChange={formHandleChange}
            />
          </Grid>
        ) : null}
        {selectedAnnotation ? (
          <Grid item xs={12} px={2} py={2}>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.grey[900] }}>
              {selectedAnnotation?.text}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default AnnotationsModalDetail;
