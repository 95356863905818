import {
  Cake as CakeIcon,
  ContactEmergency,
  Email as EmailIcon,
  Event as EventIcon,
  FilterList as FilterListIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  Today as TodayIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  PractitionerWrapper,
  WrappedPractitioner,
} from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import {
  PractitionerRoleWrapper,
  WrappedPractitionerRole,
} from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import { PatientManager } from 'src/@types/crs/patient';
import { PaginateQueryWithStats } from 'src/api/pagination/dtos';
import { fhirClient } from 'src/App';
import FilterDrawer from 'src/components/FilterDrawer';
import Iconify from 'src/components/Iconify';
import { TableHeadCustom } from 'src/components/table';
import { PatientDto } from 'src/crs/dto/patient/patient.dto';
import { patientService } from 'src/crs/patient/services';
import useLocales from 'src/hooks/useLocales';
import useNotiMessage from 'src/hooks/useNotiMessage';
import useObjectState from 'src/hooks/useObjectState';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import {
  Bundle,
  Practitioner,
  PractitionerRole,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getReferenceId } from 'src/utils/fhir';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { ACLValidationMode, checkAclValidation } from 'src/utils/permissions/permission.utils';
import { debounce, wait } from 'src/utils/timers';
import { PatientRow } from '.';
import LoadingTableRow from '../../../components/table/LoadingTableRow';
import { PaginatedPatientDto } from '../../../crs/patient/services/patientService';
import useTable from '../../../hooks/useTable';
import { TableToolbar } from '../common';
import NoResultsTableRow from '../common/NoResultsTableRow';
import { TABLE_HEAD_PATIENT_LIST } from '../common/table-head';
import { translateTableHead } from '../helpers/common';
import { PatientLayout } from '../PatientLayout';
import { GeneralPractitionerGrid } from '../types';
import AddPatientModal from './components/AddPatientModal';

export const PatientHeaderColumn = [
  {
    headerColumn: 'id',
    value: 'indexedMrn',
    label: 'MRN',
    icon: (
      <Iconify
        icon="mdi:letter-m"
        sx={{ fontSize: '2.2rem', color: '#919eab', marginBottom: '-10px', marginLeft: '-7px' }}
      />
    ),
  },
  {
    headerColumn: 'id',
    value: 'indexedCustomIdentifierMedicaidId',
    label: 'Medicaid ID',
    icon: (
      <ContactEmergency
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'patient',
    value: 'indexedGivenName',
    label: 'Name',
    icon: (
      <PersonIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'patient',
    value: 'indexedDob',
    label: 'Date of Birth',
    icon: (
      <CakeIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-2px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'contact',
    value: 'indexedPhone',
    label: 'Phone',
    icon: (
      <PhoneIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'contact',
    value: 'indexedEmail',
    label: 'Email',
    icon: (
      <EmailIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-4px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'dates',
    value: 'indexedCreatedAt',
    label: 'Registration Date',
    icon: (
      <TodayIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-4px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'dates',
    value: 'indexedMetaLastUpdatedOnUtc',
    label: 'Last Updated',
    icon: (
      <EventIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-4px' }}
        fontSize={'small'}
      />
    ),
  },
];

export const initialServiceFilter = [
  {
    code: '1',
    display: 'Service Integration Level 1',
  },
  {
    code: '2',
    display: 'Service Integration Level 2',
  },
  {
    code: '3',
    display: 'Service Integration Level 3',
  },
];
export type UsersView = { code: string; label: string };

export interface GridState {
  page: number;
  rowsPerPage: number;
  orderBy: string | null;
  orderDirection: 'asc' | 'desc' | null;
  search: string;
  silFilter: ValueSetComposeIncludeConcept[];
  ownersToFilter: GeneralPractitionerGrid[];
  careTeamMembersToFilter: UsersView[];
  enrollment: 'active' | 'finished' | 'no-enrollment' | 'all';
}

interface PatientListProps {
  includeBreadcrumbs?: boolean;
  tableData: PaginatedPatientDto | null | undefined;
  setTableData: Dispatch<SetStateAction<PaginatedPatientDto | null | undefined>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  totalItems: number;
  setTotalItems: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  initialGridState: GridState;
  ownersToFilter: GeneralPractitionerGrid[] | null;
  setOwnersToFilter: Dispatch<SetStateAction<GeneralPractitionerGrid[] | null>>;
  careTeamMembersToFilter: UsersView[] | null;
  setCareTeamMembersToFilter: Dispatch<SetStateAction<UsersView[] | null>>;
  includeStats?: boolean;
  setStats?: Dispatch<SetStateAction<any>>;
  show?: boolean;
  showFilters?: boolean;
  useOwnerFilter?: boolean;
  useCareTeamMemberFilter?: boolean;
}

export function PatientList({
  includeBreadcrumbs,
  tableData,
  setTableData,
  search,
  setSearch,
  totalItems,
  setTotalItems,
  initialGridState,
  isLoading,
  setIsLoading,
  ownersToFilter,
  setOwnersToFilter,
  careTeamMembersToFilter,
  setCareTeamMembersToFilter,
  includeStats = false,
  setStats,
  show = true,
  showFilters = true,
  useOwnerFilter = true,
  useCareTeamMemberFilter = false,
}: PatientListProps) {
  const { i18n } = useLocales();
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    setPage,
    onSort,
    setOrder,
  } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'id',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });
  const { componentsData } = useTenantConfigData();
  const { showSILInformation } = componentsData ?? {};
  const [gridState, updateGridState] = useObjectState<GridState>(initialGridState);
  const [filterMrn, setFilterMrn] = useState('');

  const [silFilters, setSilFilters] = useState();
  const [isOpenAplyFilters, setIsOpenAplyFilters] = useState<boolean>(false);
  const { showErrorMessage } = useNotiMessage();
  const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false);
  const [generalPractitioners, setGeneralPractitioners] = useState<GeneralPractitionerGrid[]>([]);

  const getPaginateQuery = (
    page: number,
    rowsPerPage: number,
    search: string,
    silFilters: any,
    orderBy: [string, string],
    ownersToFilter: GeneralPractitionerGrid[] | null,
    careTeamMembersToFilter: UsersView[] | null,
    useOwnerFilter: boolean,
    useCareTeamMemberFilter: boolean,
    includeStats?: boolean,
    enrollment?: 'active' | 'finished' | 'no-enrollment' | 'all'
  ) => {
    const paginateQuery: PaginateQueryWithStats = { page: page, limit: rowsPerPage };
    if (search) {
      paginateQuery.search = search;
    }
    paginateQuery.filter = {};

    const [field, order] = orderBy;

    if (field && order) {
      paginateQuery.sortBy = [[field, order]];
    }

    if (silFilters?.length) {
      paginateQuery.filter.indexedCustomLastSil = `$in:${silFilters
        .map(({ code }: { code: any }) => code)
        .join(',')}`;
    }

    if (useOwnerFilter && ownersToFilter && ownersToFilter?.length > 0) {
      paginateQuery.filter.indexedGeneralPractitioners = `${ownersToFilter
        .map(({ reference }: GeneralPractitionerGrid) => reference)
        .join(',')}`;
    }

    if (useCareTeamMemberFilter && careTeamMembersToFilter && careTeamMembersToFilter.length) {
      paginateQuery.filter.indexedCareTeamsPractitioners = `${careTeamMembersToFilter
        .map(({ code }: { code: any }) => code)
        .join(',')}`;
    }

    if (includeStats) {
      paginateQuery.includeStats = true;
    }

    if (enrollment) {
      paginateQuery.filter.enrollment = enrollment;
    }

    return paginateQuery;
  };

  const getGeneralPractitioners = useCallback(async () => {
    try {
      setIsLoading(true);

      const prResponse = await fhirClient.get<Bundle<PractitionerRole>>(`PractitionerRole`);

      let practitionerRoles =
        prResponse?.entry?.reduce?.<WrappedPractitionerRole[]>((resources, { resource }) => {
          if (!resource) return resources;
          return [...resources, PractitionerRoleWrapper(resource)];
        }, []) ?? [];

      const [practitionerReferenceIds] = practitionerRoles.reduce<[string[]]>(
        ([practitionerIds], practitionerRole) => {
          const practitionerId = getReferenceId(practitionerRole?.practitioner?.reference);
          if (practitionerId) practitionerIds.push(practitionerId);
          return [practitionerIds];
        },
        [[]]
      );

      const allPractitionerIds = [...new Set([...practitionerReferenceIds])];

      const practitioneresponse = allPractitionerIds.length
        ? await fhirClient.get<Bundle<Practitioner>>(
            `Practitioner?_id=${allPractitionerIds.join()}`
          )
        : null;

      const practitioners =
        practitioneresponse?.entry?.reduce?.<WrappedPractitioner[]>((resources, { resource }) => {
          if (!resource) return resources;
          return [...resources, PractitionerWrapper(resource)];
        }, []) ?? [];

      const generalPractitioners: GeneralPractitionerGrid[] = practitionerRoles
        .map((resource) => {
          const practitionerRole = resource as WrappedPractitionerRole;
          const practitioner = practitioners.find(
            ({ id }) => id === getReferenceId(practitionerRole?.practitioner?.reference)
          );

          return {
            id: practitionerRole.id,
            reference: `${practitionerRole?.resourceType}/${practitionerRole.id}`,
            role: practitionerRole?.code?.[0],
            roleActive: practitionerRole.active,
            ...(practitioner?.id && {
              practitioner: {
                id: practitioner?.id,
                fullName: practitioner?.getFullName?.(),
              },
            }),
          };
        })
        .filter((generalPractitioner) => !!generalPractitioner) as GeneralPractitionerGrid[];

      setGeneralPractitioners(generalPractitioners);
    } catch (error) {}
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getGeneralPractitioners();
  }, [getGeneralPractitioners]);

  const fetchPatientList = async (
    page: number,
    rowsPerPage: number,
    search: string,
    silFilters: any,
    orderBy: [string, string],
    ownersToFilter: GeneralPractitionerGrid[] | null,
    careTeamMembersToFilter: UsersView[] | null,
    useOwnerFilter: boolean,
    useCareTeamMemberFilter: boolean,
    enrollment?: 'active' | 'finished' | 'no-enrollment' | 'all'
  ) => {
    setIsLoading(true);
    await wait();
    const paginateQuery = getPaginateQuery(
      page,
      rowsPerPage,
      search,
      silFilters,
      orderBy,
      ownersToFilter,
      careTeamMembersToFilter,
      useOwnerFilter,
      useCareTeamMemberFilter,
      includeStats,
      enrollment
    );
    const patients = await patientService.getAll(paginateQuery);
    setIsLoading(false);
    if (!patients) return showErrorMessage(i18n('error.patient.list'));

    if (patients?.meta?.totalItems !== undefined) setTotalItems(patients?.meta?.totalItems);

    setTableData(patients);

    if (includeStats && setStats && patients?.stats) {
      setStats(patients?.stats);
    }
  };

  const handlerClear = () => {
    updateGridState({
      ownersToFilter: [],
      silFilter: undefined,
    });
    setOwnersToFilter(null);
    setSilFilters(undefined);
  };

  const getPatientList = useCallback(debounce(fetchPatientList, 600), []);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleSearch = (query: string) => {
    setSearch(query);
    setPage(1);
  };

  useEffect(() => {
    getPatientList(
      page,
      rowsPerPage,
      search,
      silFilters,
      [orderBy, order],
      ownersToFilter,
      careTeamMembersToFilter,
      useOwnerFilter,
      useCareTeamMemberFilter
    );
  }, [
    page,
    rowsPerPage,
    filterMrn,
    search,
    order,
    orderBy,
    ownersToFilter,
    careTeamMembersToFilter,
    useOwnerFilter,
    useCareTeamMemberFilter,
  ]);

  const patientDtoToPatientManager = (ref: PatientDto): PatientManager => {
    const upAtFormat = moment(ref.indexedUpdatedAt).format('MMM DD, YYYY');
    const lastUpdatedFormat = ref.metaLastUpdated
      ? moment(ref.metaLastUpdated).format('MMM DD, YYYY')
      : null;
    const row = {
      id: ref.indexedFhirRefUri?.slice(
        ref.indexedFhirRefUri?.lastIndexOf('/') + 1,
        ref.indexedFhirRefUri?.length
      ),
      mrn: ref.indexedMrn,
      referral: ref.indexedFullName,
      phone: ref.indexedPhone,
      email: ref.indexedEmail,
      birthDate: ref.indexedDob,
      gender: ref.indexedGender,
      demographics: ref.indexedEthnicity,
      race: ref.indexedRace,
      registrationDate: ref.indexedCreatedAt,
      formattedBirthDate: ref.indexedDOBFormatted,
      age: ref.indexedAge,
      registrationDateFormatted: ref.indexedCreatedAtFormatted,
      medicaId: ref.indexedCustomIdentifierMedicaidId,
      sil: ref.indexedCustomLastSil,
      createdAt: ref.indexedCreatedAtFormatted,
      updatedAt: upAtFormat,
      lastUpdated: lastUpdatedFormat,
      status: ref.indexedStatus,
      episodeOfCareData: ref.indexedCustomEpisodeOfCare,
    };
    return row;
  };

  const dataFormatted = (): PatientManager[] => {
    const data = tableData?.data ? tableData.data.map((r) => patientDtoToPatientManager(r)) : [];
    return data;
  };

  const rows = dataFormatted();

  const tableHeadPatientListFilter = TABLE_HEAD_PATIENT_LIST.filter((column) => {
    return showSILInformation || column.id !== 'indexedCustomLastSil';
  });

  const headLabel = translateTableHead(tableHeadPatientListFilter, 'crs');

  if (!show) {
    return null;
  }

  return (
    <>
      <PatientLayout
        title={i18n('patients.list.title', 'crs')}
        button={
          <>
            {checkAclValidation(
              { acls: [crsAcls.CRS.PATIENT.MANUAL_ADD] },
              ACLValidationMode.EXCLUSIVE
            ) && (
              <Grid item xs={12}>
                <Stack alignItems="flex-end">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsAddPatientModalOpen(true);
                    }}
                  >
                    {i18n('patients.list.addPatient', 'crs')}
                  </Button>
                </Stack>
              </Grid>
            )}
          </>
        }
        includeBreadcrumbs={includeBreadcrumbs}
      >
        <>
          <Grid item xs={12}>
            <Card>
              <Stack direction="row" sx={{ py: 2, px: 2 }}>
                <Grid xs={6}>
                  <TableToolbar filterMrn={search} onFilterMrn={handleSearch} />
                </Grid>
                {showFilters ? (
                  <Grid container alignItems="center" justifyContent="flex-end" xs={2}>
                    <Tooltip title="Filter">
                      <IconButton
                        onClick={() => {
                          setIsOpenAplyFilters(true);
                        }}
                        sx={{ height: '40px' }}
                        disabled={isLoading}
                      >
                        <FilterListIcon htmlColor="#919eab" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}
              </Stack>
              <TableContainer>
                <Table>
                  <TableHeadCustom
                    headLabel={headLabel}
                    onSort={onSort}
                    orderBy={orderBy}
                    order={order}
                    headerCustomColumn={PatientHeaderColumn}
                    setOrder={setOrder}
                    hideSortIcon={false}
                  />
                  <TableBody>
                    {!isLoading ? (
                      rows?.length ? (
                        rows.map((row) => <PatientRow key={row.id} row={row} />)
                      ) : (
                        <NoResultsTableRow
                          colSpan={TABLE_HEAD_PATIENT_LIST.length}
                          text={i18n('patients.list.listNotFound', 'crs')}
                        />
                      )
                    ) : (
                      <LoadingTableRow colSpan={TABLE_HEAD_PATIENT_LIST.length} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid xs={12}>
                <Stack justifyContent="flex-end">
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={totalItems ? totalItems : 0}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    sx={{ borderTop: 0 }}
                  />
                </Stack>
              </Grid>
            </Card>
          </Grid>
          <FilterDrawer
            onApplyButtonClick={() => {
              getPatientList(
                page,
                rowsPerPage,
                search,
                gridState.silFilter,
                [order, orderBy],
                gridState.ownersToFilter,
                gridState.careTeamMembersToFilter,
                useOwnerFilter,
                useCareTeamMemberFilter,
                gridState.enrollment
              );
              setIsOpenAplyFilters(false);
            }}
            title={i18n('patients.list.filterPopUp.title', 'crs')}
            onCloseIconButtonClick={() => {
              setIsOpenAplyFilters(false);
            }}
            onClearAllButtonClick={handlerClear}
            anchor={'right'}
            open={isOpenAplyFilters}
          >
            <PatientFiltersList
              gridState={gridState}
              updateGridState={updateGridState}
              setSilFilter={setSilFilters}
              setOwnersToFilter={setOwnersToFilter}
              patientSil={[]}
              generalPractitioners={generalPractitioners}
              i18n={i18n}
              includeStats={includeStats}
            />
          </FilterDrawer>
        </>
      </PatientLayout>
      <AddPatientModal
        open={isAddPatientModalOpen}
        onClose={() => {
          setIsAddPatientModalOpen(false);
        }}
      />
    </>
  );
}

interface PatientFilterListProps {
  gridState: any;
  updateGridState: any;
  patientSil: any;
  setSilFilter: any;
  setOwnersToFilter: Dispatch<SetStateAction<GeneralPractitionerGrid[] | null>>;
  generalPractitioners: GeneralPractitionerGrid[];
  i18n: any;
  includeStats?: boolean;
}

const PatientFiltersList = ({
  gridState,
  updateGridState,
  patientSil,
  setSilFilter,
  setOwnersToFilter,
  generalPractitioners,
  i18n,
  includeStats,
}: PatientFilterListProps) => {
  const { componentsData } = useTenantConfigData();
  const { showSILInformation } = componentsData ?? {};
  return (
    <Box py={3}>
      <Box>
        <Grid item sx={{ marginTop: 1 }} xs={12}>
          <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1.4}>
            {i18n('patients.list.filterPopUp.owners', 'crs')}
          </Typography>
          <Autocomplete
            id="combo-box-user"
            value={gridState.ownersToFilter}
            fullWidth
            multiple
            onChange={(_: React.SyntheticEvent, generalPractitioner: any) => {
              if (includeStats) setOwnersToFilter(generalPractitioner);
              updateGridState((prevGridState: any) => ({
                ...prevGridState,
                ownersToFilter: generalPractitioner,
              }));
            }}
            options={generalPractitioners ?? []}
            getOptionLabel={(option) =>
              `${option?.practitioner?.fullName} | ${option?.role?.coding?.[0]?.display}`
            }
            renderInput={(params) => (
              <TextField
                label={i18n('patients.list.filterPopUp.ownersPlaceHolder', 'crs')}
                {...params}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option?.id}>
                {`${option?.practitioner?.fullName} | ${option?.role?.coding?.[0]?.display}`}
              </li>
            )}
          />
        </Grid>
        <br />

        <Grid item sx={{ marginTop: 1 }} xs={12}>
          <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1.4}>
            {i18n('patients.list.filterPopUp.enrollment', 'crs')}
          </Typography>
          <Autocomplete
            id="combo-box-enrollment"
            value={gridState.enrollment}
            fullWidth
            onChange={(_: React.SyntheticEvent, enrollment: any) => {
              updateGridState((prevGridState: any) => ({
                ...prevGridState,
                enrollment: enrollment,
              }));
            }}
            options={['active', 'finished', 'un-enrolled', 'all']}
            // getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                label={i18n('patients.list.filterPopUp.enrollmentPlaceHolder', 'crs')}
                {...params}
              />
            )}
            renderOption={(props, option, index) => (
              <li {...props} key={index + option}>
                {`${option}`}
              </li>
            )}
          />
        </Grid>
        <br />

        {!!showSILInformation && (
          <Grid item sx={{ marginTop: 1 }} xs={12}>
            <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1.4}>
              {i18n('patients.list.filterPopUp.sil', 'crs')}
            </Typography>
            <Autocomplete
              value={gridState.silFilter}
              multiple
              fullWidth
              onChange={(_: React.SyntheticEvent, silFilteres) => {
                setSilFilter(silFilteres);
                updateGridState((prevGridState: any) => ({
                  ...prevGridState,
                  silFilter: silFilteres,
                }));
              }}
              options={initialServiceFilter ?? []}
              getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18n('patients.list.filterPopUp.silPlaceHolder', 'crs')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
      </Box>
    </Box>
  );
};
