import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchTextField from 'src/components/SearchTextField';
import useObjectState from 'src/hooks/useObjectState';
import { DataGrid, GridColDef, GridRow } from '@mui/x-data-grid';
import {
  useCareTeams,
  useOrganizations,
  usePractitioners,
  useRelatedPersons,
  useValueSets,
} from 'src/@nicheaim/fhir-react';
import { CareTeamWrapper, WrappedCareTeam } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import {
  PractitionerWrapper,
  WrappedPractitioner,
} from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import {
  PractitionerRoleWrapper,
  WrappedPractitionerRole,
} from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import { IdentifierCode, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import {
  CareTeamMember,
  Option,
  MemberEditableData,
  CareTeamMemberWithEditableData,
  CareTeamPermissions,
} from 'src/@types/crs/case';
import { GridItem, GridSection } from 'src/components/CustomModal';
import {
  Bundle,
  Coding,
  FhirResource,
  Organization,
  Practitioner,
  PractitionerRole,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import DatePickerMoment from 'src/components/DatePickerMoment';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { checkIfDateIsAfterAnother } from 'src/utils/dates';
import { debounce } from 'src/utils/timers';
import { getReference, searchIfContainedInObj } from 'src/sections/crs/helpers/common';
import { NOTAPPLICABLE, isActiveCondition } from 'src/sections/crs/constants';
import usePermissionsContext from 'src/hooks/usePermissionsContext';
import { cleanSearchInput } from 'src/utils/string';
import { getResourcesAndRelatedResources } from 'src/utils/fhir';
import {
  OrganizationWrapper,
  WrappedOrganization,
} from 'src/@nicheaim/fhir-base/wrappers/Organization';
import {
  RelatedPersonWrapper,
  WrappedRelatedPerson,
} from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import { fhirClient } from 'src/App';
import { ResourceWithIncludedResources } from 'src/sections/crs/types';
import { formatUSAddress } from 'src/utils/address';
import CellRow from 'src/sections/crs/common/CellRow';

interface SearchMemberState {
  searchTextField: string;
  filter: string | null;
  resourceType: string | null;
}

export type MemberResourceTypes =
  | 'Patient'
  | 'Practitioner'
  | 'PractitionerRole'
  | 'CareTeam'
  | 'Organization'
  | 'RelatedPerson';

export type onSelectResource = (
  resource: MemberWrappedResourceTypes,
  includedResources?: FhirResource[]
) => void;

type MemberWrappedResourceTypes =
  | WrappedPatient
  | WrappedCareTeam
  | WrappedPractitioner
  | WrappedPractitionerRole
  | WrappedOrganization
  | WrappedRelatedPerson;

interface GridRow extends Partial<CareTeamMember> {
  id: string;
  memberType: string;
  resource?: MemberWrappedResourceTypes;
  includedResources?: FhirResource[];
}

export interface SearchMemberProps {
  patient?: WrappedPatient;
  showMemberTypeField?: boolean;
  onSelectResource?: onSelectResource;
  externalResourceType?: MemberResourceTypes;
  onClear?: () => void;
  isEditable?: boolean;
  careTeamMembers?: CareTeamMember[];
  isLoading?: boolean;
  membersSelectionRef?: React.MutableRefObject<CareTeamMemberWithEditableData[]>;
}

export type MemberResourceTypeOption = Option<MemberResourceTypes>;

const pagination = {
  pageSize: 50,
};

const columnsDefaultWidth = 250;

const getDefaultColumnWidth = (resourceType: string | null, columnWidth = columnsDefaultWidth) => {
  if (!resourceType) return { width: columnWidth, flex: 0 };
  return {};
};

const SearchMember = ({
  externalResourceType,
  onSelectResource,
  onClear,
  patient,
  showMemberTypeField = false,
  isEditable,
  careTeamMembers,
  isLoading,
  membersSelectionRef,
}: SearchMemberProps) => {
  const [{ filter, searchTextField, resourceType }, updateState] =
    useObjectState<SearchMemberState>({
      searchTextField: '',
      filter: null,
      resourceType: null,
    });

  const [{ practitionerRoles, isPractitionerRolesLoading }, updatePractitionerRoleState] =
    useObjectState<{
      isPractitionerRolesLoading: boolean;
      practitionerRoles: ResourceWithIncludedResources[];
    }>({
      practitionerRoles: [],
      isPractitionerRolesLoading: false,
    });

  const practitionerFilter = useMemo(() => {
    const cleansedSearch = filter ? cleanSearchInput(filter) : '';
    if (!cleansedSearch.length) return isActiveCondition;
    const splittedSearch = cleansedSearch ? cleansedSearch.split(' ') : [];
    const givenName = splittedSearch?.[0] ?? '';
    const familyName = splittedSearch.slice(1).join(' ');

    return splittedSearch.length === 1
      ? {
          name: givenName,
          ...isActiveCondition,
        }
      : {
          given: givenName,
          ...(familyName ? { family: familyName } : {}),
          ...isActiveCondition,
        };
  }, [filter]);

  const getPractitionerRoles = useCallback(async () => {
    if (resourceType !== 'PractitionerRole') return;
    try {
      updatePractitionerRoleState({ isPractitionerRolesLoading: true });
      const cleansedSearch = filter ? cleanSearchInput(filter) : '';
      const splittedSearch = cleansedSearch ? cleansedSearch.split(' ') : [];
      const givenName = splittedSearch?.[0] ?? '';
      const familyName = splittedSearch.slice(1).join(' ');

      const includeQuery = `_include=PractitionerRole:practitioner&_include=PractitionerRole:organization`;

      const filterPRByPractitioners = {
        ...(givenName && familyName
          ? {
              'practitioner.given': givenName,
              ...(familyName
                ? {
                    'practitioner.family': familyName,
                  }
                : {}),
            }
          : {
              'practitioner.name': cleansedSearch,
            }),
        ...isActiveCondition,
      };
      const filterPRByOrganizations = {
        'organization.name': cleansedSearch,
        ...isActiveCondition,
      };
      const filterPR = {
        ...(cleansedSearch ? { 'role:text': cleansedSearch } : {}),
        ...isActiveCondition,
      };

      const practitionerRolesResources = await fhirClient.get<Bundle>(
        `PractitionerRole?${new URLSearchParams(filterPR).toString()}&${includeQuery}`
      );
      const pRolesByPractitioner = cleansedSearch
        ? await fhirClient.get<Bundle>(
            `PractitionerRole?${new URLSearchParams(
              filterPRByPractitioners
            ).toString()}&${includeQuery}`
          )
        : null;

      const pRolesByOrganization = cleansedSearch
        ? await fhirClient.get<Bundle>(
            `PractitionerRole?${new URLSearchParams(
              filterPRByOrganizations
            ).toString()}&${includeQuery}`
          )
        : null;

      const [practitionerRoles, relatedResources] = getResourcesAndRelatedResources(
        [
          ...(practitionerRolesResources?.entry ?? []),
          ...(pRolesByPractitioner?.entry ?? []),
          ...(pRolesByOrganization?.entry ?? []),
        ].reduce<FhirResource[]>((resources, { resource }) => {
          if (resource) return [...resources, resource];
          return resources;
        }, []),
        'PractitionerRole'
      );
      updatePractitionerRoleState({
        practitionerRoles: (practitionerRoles as PractitionerRole[]).map((practitionerRole) => {
          const organization = relatedResources.find(
            ({ id }) => id === practitionerRole?.organization?.reference?.split?.('/')?.[1]
          );
          const practitioner = relatedResources.find(
            ({ id }) => id === practitionerRole?.practitioner?.reference?.split?.('/')?.[1]
          );
          return {
            resource: PractitionerRoleWrapper(practitionerRole),
            includedResources: [
              ...(organization ? [OrganizationWrapper(organization as Organization)] : []),
              ...(practitioner ? [PractitionerWrapper(practitioner as Practitioner)] : []),
            ],
          };
        }),
      });
    } catch (error) {}

    updatePractitionerRoleState({ isPractitionerRolesLoading: false });
  }, [filter, resourceType]);

  useEffect(() => {
    getPractitionerRoles();
  }, [getPractitionerRoles]);

  const [membersSelected, setMembersSelected] = useState<CareTeamMember[]>(careTeamMembers ?? []);

  const [membersEditableData, setMembersEditableData] = useState<MemberEditableData[]>([]);

  const { isAllowedToEdit, isAllowedToDelete, isAllowedToAdd } =
    usePermissionsContext<CareTeamPermissions['members']>() ?? {};

  useEffect(() => {
    if (!careTeamMembers?.length) return;
    const membersEditableData: MemberEditableData[] = careTeamMembers.map(
      ({ id, memberType, endDateObj, startDateObj, role, roleId }) => ({
        id,
        memberType,
        startDate: startDateObj,
        endDate: endDateObj,
        role: roleId ? { code: roleId, display: role } : null,
        error: {
          startDate: null,
        },
      })
    );
    setMembersEditableData(membersEditableData);
    setMembersSelected(careTeamMembers);
  }, [careTeamMembers]);

  useEffect(() => {
    if (!externalResourceType) return;
    updateState({ resourceType: externalResourceType });
  }, [externalResourceType]);

  const findMemberEditableData = useCallback(
    (id: string, memberType: string): MemberEditableData | undefined =>
      membersEditableData.find(
        (memberEditableData) =>
          memberEditableData.id === id && memberEditableData.memberType === memberType
      ),
    [membersEditableData]
  );

  useEffect(() => {
    if (!membersSelectionRef?.current) return;
    const membersWithEditableData: CareTeamMemberWithEditableData[] = membersSelected.map(
      (memberSelected) => {
        const memberEditableData = (findMemberEditableData(
          memberSelected.id,
          memberSelected.memberType
        ) ?? {}) as MemberEditableData;
        return {
          ...memberSelected,
          editableData: {
            ...memberEditableData,
          },
        };
      }
    );
    membersSelectionRef.current = membersWithEditableData;
  }, [membersSelected, findMemberEditableData]);

  const [rolesRecords] = useValueSets({
    filter: {
      identifier: 'ph-careteam-roles',
    },
    map: ValueSetWrapper,
  });

  const roles = useMemo(() => rolesRecords?.[0] ?? null, [rolesRecords]);

  const [careTeams, { isFetching: isCareTeamFetching }] = useCareTeams({
    map: CareTeamWrapper,
    autofetch: resourceType === 'CareTeam',
    // filter: {
    //   patient: patient?.id,
    // },
    ...pagination,
  });
  const [practitioners, { isFetching: isPractitionerFetching }] = usePractitioners({
    filter: practitionerFilter,
    map: PractitionerWrapper,
    autofetch: resourceType === 'Practitioner',
    ...pagination,
  });

  const [organizations, { isFetching: isOrganizationsFetching }] = useOrganizations({
    filter: {
      '_content': filter || undefined
    },

    map: OrganizationWrapper,
    autofetch: resourceType === 'Organization',
    ...pagination,
  });

  const [relatedPersons, { isFetching: isRelatedPerson }] = useRelatedPersons({
    filter: {
      name: filter || undefined,
      patient: patient?.id ?? undefined,
    },
    map: RelatedPersonWrapper,
    autofetch: resourceType === 'RelatedPerson',
    ...pagination,
  });

  const isFetching =
    isCareTeamFetching ||
    isPractitionerFetching ||
    isPractitionerRolesLoading ||
    isOrganizationsFetching ||
    isRelatedPerson;

  const getResourceByResourceType = useCallback(
    (resourceType: string) => {
      switch (resourceType) {
        case 'CareTeam':
          return careTeams;
        case 'Practitioner':
          return practitioners;
        case 'PractitionerRole':
          return practitionerRoles;
        case 'Organization':
          return organizations;
        case 'RelatedPerson':
          return relatedPersons;
        case 'Patient':
          return [patient];
      }
    },
    [patient, careTeams, practitioners, practitionerRoles, organizations, relatedPersons]
  );

  const handleFilterChange = useCallback(
    debounce((value) => {
      updateState({ filter: value });
    }, 600),
    []
  );

  const showMemberTypeSelectionField = showMemberTypeField || isEditable;
  const memberTypes = !isEditable
    ? getMemberResourceTypes(!!patient)
    : getMemberResourceTypes(!!patient).filter(({ value }) => value !== 'Patient');

  const handleMemberSelection = useCallback(
    (member: CareTeamMember, isMemberSelected: boolean): void => {
      if (isMemberSelected) {
        setMembersSelected((membersSelected) =>
          membersSelected.filter(
            ({ id, memberType }) =>
              getReference(memberType, id) !== getReference(member.memberType, member.id)
          )
        );
      } else {
        setMembersSelected((membersSelected) => [...membersSelected, member]);
      }
    },
    []
  );

  const handleMemberEditableDataChange = useCallback(
    (
      memberEditableData: MemberEditableData | undefined | null,
      newData: Partial<MemberEditableData>
    ) => {
      if (!memberEditableData) {
        const member: MemberEditableData = {
          id: '',
          memberType: '',
          startDate: null,
          endDate: null,
          role: null,
          error: {
            startDate: null,
          },
          ...newData,
        };
        if (checkIfDateIsAfterAnother(member?.startDate ?? null, member?.endDate ?? null)) {
          member.error.startDate = "Start Date can't be after End Date";
        } else {
          member.error.startDate = null;
        }
        setMembersEditableData([...membersEditableData, member]);
        return;
      }
      for (const [key, value] of Object.entries(newData) as Array<
        [keyof MemberEditableData, any]
      >) {
        if (!memberEditableData?.hasOwnProperty?.(key)) continue;
        memberEditableData[key] = value;
      }
      if (
        checkIfDateIsAfterAnother(
          memberEditableData?.startDate ?? null,
          memberEditableData?.endDate ?? null
        )
      ) {
        memberEditableData.error.startDate = "Start Date can't be after End Date";
      } else {
        memberEditableData.error.startDate = null;
      }
      setMembersEditableData([...membersEditableData]);
    },
    [membersEditableData]
  );

  const defaultEditableColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'startDate',
        headerName: 'Start Date',
        sortable,
        ...getDefaultColumnWidth(resourceType, columnsDefaultWidth + 30),
        ...(resourceType ? { flex: 1.6 } : {}),
        renderCell: (params) => {
          const { id, memberType } = params.row as GridRow;
          const memberEditableData = findMemberEditableData(id, memberType);
          return (
            <>
              {isAllowedToEdit ? (
                <DatePickerMoment
                  disabled={!!isLoading}
                  value={memberEditableData?.startDate ?? null}
                  error={memberEditableData?.error?.startDate ?? null}
                  label={'Start Date'}
                  onChange={(value) => {
                    handleMemberEditableDataChange(memberEditableData, {
                      id,
                      memberType,
                      startDate: value,
                    });
                  }}
                />
              ) : (
                <Typography>{memberEditableData?.startDate ?? null}</Typography>
              )}
            </>
          );
        },
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        sortable,
        ...getDefaultColumnWidth(resourceType, columnsDefaultWidth + 30),
        ...(resourceType ? { flex: 1.6 } : {}),
        renderCell: (params) => {
          const { id, memberType } = params.row as GridRow;
          const memberEditableData = findMemberEditableData(id, memberType);
          return (
            <>
              {isAllowedToEdit ? (
                <DatePickerMoment
                  disabled={!!isLoading}
                  value={memberEditableData?.endDate ?? null}
                  label={'End Date'}
                  onChange={(value) => {
                    handleMemberEditableDataChange(memberEditableData, {
                      id,
                      memberType,
                      endDate: value,
                    });
                  }}
                />
              ) : (
                <Typography>{memberEditableData?.endDate ?? null}</Typography>
              )}
            </>
          );
        },
      },
    ],
    [resourceType, findMemberEditableData, handleMemberEditableDataChange]
  );

  const defaultColumns: Record<string, GridColDef> = useMemo(
    () => ({
      name: {
        field: 'name',
        headerName: 'Name',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      memberType: {
        field: 'memberType',
        headerName: 'Member Type',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      identifier: {
        field: 'identifier',
        headerName: 'Identifier',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      associatedOrg: {
        field: 'associatedOrg',
        headerName: 'Associated Organization(s)',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      role: {
        field: 'role',
        headerName: 'Role',
        sortable,
        ...getDefaultColumnWidth(resourceType),
        ...(isEditable
          ? {
              renderCell: (params) => {
                const { id, memberType } = params.row as GridRow;
                const memberEditableData = findMemberEditableData(id, memberType);
                if (memberType !== 'PractitionerRole')
                  return <Typography> {NOTAPPLICABLE} </Typography>;

                return (
                  <Autocomplete
                    value={memberEditableData?.role ?? null}
                    fullWidth
                    onChange={(_: React.SyntheticEvent, role) => {
                      handleMemberEditableDataChange(memberEditableData, {
                        id,
                        memberType,
                        role,
                      });
                    }}
                    options={(roles?.compose?.include?.[0]?.concept as Coding[]) ?? []}
                    getOptionLabel={({ display }: Coding) => display ?? ''}
                    renderInput={(params) => (
                      <TextField {...params} label={'Role'} variant="outlined" />
                    )}
                  />
                );
              },
            }
          : {}),
      },
      address: {
        field: 'address',
        headerName: 'Address',
        sortable,
        ...getDefaultColumnWidth(resourceType),
        renderCell: (params) => {
          const row = params.row as GridRow;
          const organization = row?.resource as WrappedOrganization;

          return (
            <CellRow
              shouldTruncateText={false}
              title={formatUSAddress(organization?.getPrimaryAddress()) ?? ''}
            />
          );
        },
      },
    }),
    [resourceType, findMemberEditableData, handleMemberEditableDataChange, roles]
  );

  const columnsMapping: ResourceMapping<GridColDef[]> = useMemo(
    () => ({
      Practitioner: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
      ],
      Patient: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
      ],
      CareTeam: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.associatedOrg,
          flex: 1,
        },
      ],
      PractitionerRole: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.role,
          flex: 1,
        },
        {
          ...defaultColumns.associatedOrg,
          flex: 1,
        },
      ],
      Organization: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
        {
          ...defaultColumns.address,
          flex: 2,
        },
      ],
      RelatedPerson: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
      ],
    }),
    [defaultColumns, isEditable]
  );

  const actionButtonColumn: GridColDef = useMemo(
    () => ({
      field: 'edit',
      headerName: '',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const row = params.row as GridRow;
        const isMemberSelected = !!membersSelected.find(
          (memberSelected) =>
            memberSelected.id === row?.id && memberSelected.memberType === row?.memberType
        );

        const shouldDisableButton =
          isEditable &&
          ((!isAllowedToAdd && !isMemberSelected) || (!isAllowedToDelete && isMemberSelected));
        return (
          <Button
            disabled={!!isLoading || !!shouldDisableButton}
            color={!isEditable ? 'primary' : isMemberSelected ? 'error' : 'primary'}
            sx={{
              height: 36,
              width: 63,
            }}
            onClick={() => {
              if (isEditable) {
                handleMemberSelection(row as CareTeamMember, isMemberSelected);
                return;
              }
              onSelectResource?.(
                row?.resource as MemberWrappedResourceTypes,
                row?.includedResources ?? []
              );
            }}
            variant="contained"
          >
            {!isEditable ? 'Select' : isMemberSelected ? 'Remove' : 'Add'}
          </Button>
        );
      },
    }),
    [membersSelected, isEditable, handleMemberSelection, onSelectResource]
  );

  const getRows = useCallback(() => {
    if (isEditable) {
      if (!resourceType && !filter) {
        return membersSelected;
      }

      // if (resourceType && !filter) {
      //   const members = membersSelected?.filter(({ memberType }) => memberType === resourceType);
      //   if (members.length) return members;
      // }

      if (!resourceType && filter) {
        return membersSelected?.filter((memberSelected) =>
          searchIfContainedInObj(
            memberSelected,
            [
              'id',
              'name',
              'memberType',
              'identifier',
              'associatedOrg',
              'role',
              'startDate',
              'endDate',
            ],
            filter ?? ''
          )
        );
      }
    }

    const resources = getResourceByResourceType(resourceType as string);

    return resources?.map?.((resource) =>
      getRowsMapping?.[resourceType as MemberResourceTypes]?.(
        resource as MemberWrappedResourceTypes
      )
    );
  }, [resourceType, membersSelected, getResourceByResourceType, isEditable, filter]);

  const getColumns = useCallback(() => {
    const columnsByResourceType = (columnsMapping?.[resourceType as MemberResourceTypes] ??
      []) as GridColDef[];
    if (!isEditable) return [...columnsByResourceType, actionButtonColumn];

    if (resourceType)
      return [...columnsByResourceType, ...defaultEditableColumns, actionButtonColumn];

    return [...Object.values(defaultColumns), ...defaultEditableColumns, actionButtonColumn];
  }, [
    defaultColumns,
    actionButtonColumn,
    resourceType,
    columnsMapping,
    defaultEditableColumns,
    isEditable,
  ]);

  const rows = useMemo(() => getRows(), [getRows]);

  const columns = useMemo(() => getColumns(), [getColumns]);
  return (
    <>
      <GridSection mt={0}>
        {showMemberTypeSelectionField && (
          <GridItem xs={3}>
            <FormControl fullWidth>
              <InputLabel> {!isEditable ? 'Owner Type' : 'Member Type'}</InputLabel>
              <Select
                disabled={!!isLoading}
                value={resourceType}
                label={!isEditable ? 'Owner Type' : 'Member Type'}
                onChange={(event) => {
                  updateState({ resourceType: event.target.value });
                }}
              >
                {memberTypes.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        )}
        <GridItem
          xs={!showMemberTypeSelectionField ? 12 : 8.7}
          display={'flex'}
          justifyContent={'row'}
        >
          <SearchTextField
            disabled={!!isLoading}
            value={searchTextField}
            onChange={(event) => {
              const { value } = event.target;
              updateState({
                searchTextField: value,
              });
              handleFilterChange(value);
            }}
            placeholder={`Search ${
              titleMapping?.[resourceType as MemberResourceTypes]
                ? `by ${titleMapping[resourceType as MemberResourceTypes]}`
                : ''
            }...`}
            fullWidth
          />
          <Button
            disabled={!!isLoading}
            sx={{
              ml: 2,
              paddingY: 2,
              paddingX: 3,
              alignSelf: 'center',
              color: '#ff5630',
              width: 78,
              height: 26,
              border: 0,
              '&:hover': { border: 0, backgroundColor: 'rgba(255, 86, 48, 0.16)' },
            }}
            onClick={() => {
              updateState({
                searchTextField: '',
                resourceType: null,
                filter: null,
              });
              onClear?.();
            }}
            variant="outlined"
            color="inherit"
          >
            <DeleteOutlineIcon htmlColor={'#ff5630'} sx={{ mr: '1px' }} />
            <Typography
              color={'#ff5630'}
              fontSize={'0.9rem'}
              fontWeight={'bold'}
              sx={{ mr: '2px' }}
            >
              Clear
            </Typography>
          </Button>
        </GridItem>
      </GridSection>
      <GridSection>
        <GridItem xs={12}>
          <Box sx={{ width: '100%', height: 450 }}>
            {(!!resourceType || isEditable) && (
              <DataGrid
                rows={rows ?? []}
                sx={{
                  width: '100% !important',
                  '& .MuiDataGrid-columnHeader': { backgroundColor: '#f4f6f8' },
                  '& .MuiDataGrid-cell': {
                    border: 0,
                  },
                }}
                loading={isFetching}
                getRowSpacing={() => ({ bottom: 5 })}
                rowHeight={isEditable ? 145 : 56}
                columns={columns}
                rowSpacingType="margin"
                isRowSelectable={() => false}
                disableColumnFilter
                disableColumnMenu
                density={'compact'}
                components={{
                  Footer: () => <></>,
                }}
              />
            )}
          </Box>
        </GridItem>
      </GridSection>
    </>
  );
};

type ResourceMapping<T> = {
  [k in MemberResourceTypes]?: T;
};

const titleMapping: ResourceMapping<string> = {
  PractitionerRole: 'Practitioner Role',
};

const sortable = false;

const getRowsMapping: ResourceMapping<(resource: MemberWrappedResourceTypes) => GridRow> = {
  Practitioner: (resource) => {
    const { getFullName, id, getIdentifier, resourceType } = resource as WrappedPractitioner;
    const { identifier, type: identifierType } = getIdentifier();
    return {
      id: id ?? '',
      identifier: identifier ?? '',
      identifierType,
      name: getFullName() ?? '',
      associatedOrg: NOTAPPLICABLE,
      memberType: resourceType,
      resource,
    };
  },
  Patient: (resource) => {
    const { getFullName, id, getIdentifier, resourceType } = resource as WrappedPatient;
    return {
      id: id ?? '',
      identifier: getIdentifier(IdentifierCode.MEDICAL_RECORD_NUMBER)?.value ?? '',
      name: getFullName() ?? '',
      memberType: resourceType,
      resource,
    };
  },
  CareTeam: (resource) => {
    const { name, id, resourceType } = resource as WrappedCareTeam;
    return {
      id: id ?? '',
      name,
      resource,
      memberType: resourceType,
      associatedOrg: NOTAPPLICABLE,
    };
  },
  PractitionerRole: (resource) => {
    const { resource: practitionerRole, includedResources } = (resource ??
      {}) as unknown as ResourceWithIncludedResources;

    const {
      getIdentifier,
      getPractitionerName,
      getRoleName,
      getRoleId,
      resourceType,
      getOrganizationId,
      getOrganizationName,
    } = (practitionerRole ?? {}) as WrappedPractitionerRole;

    const { identifier, type: identifierType } = getIdentifier();
    return {
      id: practitionerRole?.id ?? '',
      identifier: identifier ?? '',
      identifierType,
      name: getPractitionerName((includedResources ?? []) as WrappedPractitioner[]),
      memberType: resourceType,
      role: getRoleName() ?? getRoleId(),
      roleId: getRoleId(),
      roleName: getRoleName(),
      associatedOrg: getOrganizationName() ?? getOrganizationId(),
      associatedOrgId: getOrganizationId(),
      associatedOrgName: getOrganizationName((includedResources ?? []) as WrappedOrganization[]),
      resource: practitionerRole as WrappedPractitionerRole,
      includedResources,
    };
  },
  Organization: (resource) => {
    const { id, resourceType, name } = resource as WrappedOrganization;
    return {
      id: id ?? '',
      identifier: id ?? '',
      name: name ?? '',
      memberType: resourceType,
      resource,
    };
  },
  RelatedPerson: (resource) => {
    const { id, resourceType, getFullName } = (resource ?? {}) as WrappedRelatedPerson;
    return {
      id: id ?? '',
      identifier: id ?? '',
      name: getFullName?.() ?? '',
      memberType: resourceType,
      resource,
    };
  },
};

export const getMemberResourceTypes = (showPatient: boolean = true): MemberResourceTypeOption[] => [
  ...(showPatient ? [{ label: 'Patient', value: 'Patient' } as MemberResourceTypeOption] : []),
  { label: 'Practitioner', value: 'Practitioner' },
  { label: 'Practitioner Role', value: 'PractitionerRole' },
  { label: 'Care Team', value: 'CareTeam' },
];

export default SearchMember;
