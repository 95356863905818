//
import { PATH_DASHBOARD } from 'src/routes/paths';
import useTenantConfigData from './useTenantConfigData';

const useRouteHanderByTenant = () => {
  const { componentsData } = useTenantConfigData();

  function redirectRouteAfterCreatePatient(fhirPatientId: string) {
    const redirectRouteAfterCreatePatient = componentsData.redirectRouteAfterCreatePatient;

    if (redirectRouteAfterCreatePatient && redirectRouteAfterCreatePatient !== '') {
      return redirectRouteAfterCreatePatient.replace(`:patientId`, fhirPatientId);
    }

    return PATH_DASHBOARD.crs.patient.details.forId(fhirPatientId || '');
  }

  return {
    redirectRouteAfterCreatePatient,
  };
};

export default useRouteHanderByTenant;
