import { Box, Grid } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import SearchTextField from '../../../components/SearchTextField';
import { debounce } from '../../../utils/timers';
import { cleanSearchInput } from '../../../utils/string';
import useLocales from '../../../hooks/useLocales';
import { scrollbarStyle } from '../../../utils/utilities';
import AnnotationsModalListCard from './AnnotationsModalListCard';
import { Typography } from '@mui/material';

interface AnnotationsModalListProps {
  annotations: any[];
  searchTextField: string;
  setSearchTextField: Dispatch<SetStateAction<string>>;
  searchFilter: string;
  setSearchFilter: Dispatch<SetStateAction<string>>;
  handleSelectAnnotation: (annotation: any) => void;
}

const AnnotationsModalList = ({
  annotations,
  searchTextField,
  setSearchTextField,
  searchFilter,
  setSearchFilter,
  handleSelectAnnotation,
}: AnnotationsModalListProps) => {
  const { i18n } = useLocales();

  const handleSearchInputChange = useCallback(
    debounce((value: string) => {
      setSearchFilter(value);
    }, 600),
    []
  );

  const filteredAnnotations = useMemo(
    () =>
      annotations.filter(
        (x) =>
          x?.text?.toLowerCase()?.includes(searchFilter?.toLowerCase()) ||
          x?.authorString?.toLowerCase()?.includes(searchFilter?.toLowerCase())
      ),
    [annotations, searchFilter]
  );

  return (
    <Grid container xs={12} sx={{ overflow: 'none' }} flexDirection={'column'} px={1}>
      <Grid
        container
        item
        xs={2}
        sx={{
          minWidth: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={12}>
          <Box>
            <SearchTextField
              value={searchTextField}
              onChange={(event) => {
                const { value } = event.target;
                setSearchTextField(value);
                handleSearchInputChange(value);
              }}
              placeholder={`${i18n('annotations.details.search', 'crs')}`}
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={9}
        sx={{
          minWidth: '100%',
          overflowY: 'scroll',
          overflowX: 'none',
          ...scrollbarStyle,
        }}
        flexDirection={'row'}
      >
        <Grid
          container
          item
          xs={12}
          alignContent={'start'}
          // justifyContent={'flex-start'}
          // sx={{ maxHeight: '100%', maxWidth: '100%', overflowY: 'scroll', overflowX: 'none' }}
          rowSpacing={2}
          // columnSpacing={2}
        >
          {filteredAnnotations.map((annotation, index) => (
            <Grid
              container
              item
              xs={12}
              key={`${annotation?.authorString}${annotation?.text}${annotation?.time}${index}`}
              sx={{ minHeight: '20%', maxHeight: '20%' }}
            >
              <AnnotationsModalListCard
                annotation={annotation}
                handleSelectAnnotation={handleSelectAnnotation}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid container item xs={1} sx={{ minWidth: '100%' }}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            pr={1}
            pt={2}
            sx={{
              color: (theme) => theme.palette.grey[500],
              marginLeft: 'auto',
              textAlign: 'right',
            }}
          >
            {`${filteredAnnotations.length} ${i18n('annotations.list.count', 'crs')}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnnotationsModalList;
